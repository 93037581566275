import { environment } from "./environment";

const WONDER_PUSH_WEBKEY = "5ed3799bf3940f771c60bdaf8484792b7b30845744f2d1fcda2c0553cb876153";

export const WonderPush = (window["WonderPush"] = window["WonderPush"] ?? []);

export function setupAndInitializeWonderPush() {
  WonderPush.push([
    "init",
    {
      serviceWorkerUrl: "/service-worker.js",
      webKey: WONDER_PUSH_WEBKEY,
      requiresUserConsent: environment.production,
    },
  ]);
  return WonderPush;
}
