import * as jose from "jose";
import { encode as encodeBase64Url } from "universal-base64url";

/**
 * Suite defined in "Anlage Detailspezifikationen"
 * @see https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=20009390
 *
 * - Datenerfassungsprotokoll (DEP)
 */
export const suites = {
  "R1": {
    jwsSignatureAlgorithm: "ES256",
    hashAlgorithmForPreviousSignatureValue: "SHA-256",
    numberOfBytesExtractedFromPrevSigHash: 8,
    supportedPrefixes: ["R1-AT"]
  },
};

/**
 * Trust service provider (TSP)
 * -> Vertrauensdiensteanbieter (VDA)
 *
 *   @formerly: Certification service provider (CSP)
 *   -> Zertifizierungsdiensteanbieters (ZDA)
 */
 export const trustServiceProviders = {
  "AT0": { label: "closed system"},
  "AT1": { label: "A-Trust" },
  "AT2": { label: "Global Trust" },
  "AT3": { label: "PrimeSign" },
  "AT100": { label: "open system" },
};

function base64ToBase64Url(base64: string) {
  return String(base64).replace(/=+$/, "").replace(/\+/g, "-").replace(/\//g, "_");
}

export class Receipt {

  raw: any[];
  suite: any;
  jws: string;
  info: {[name: string]: any;};

  constructor(data) {
    const raw = getRawReceiptDataParts(data);

    const suiteId = String(raw[1]).split("-")[0];
    const suite = suites[suiteId];

    if (suite == null) {
      throw new Error(`Suite ${suiteId} not supported`);
    }

    this.raw = raw;
    this.suite = suite;

    this.jws = getJwsFromReceiptData(raw);
    this.info = decodeReceiptData(raw);
  }

  async isValid(key) {
    try {
      await jose.compactVerify(this.jws, key);
      return true;
    } catch {
      return false;
    }
  }
}

export function getRawReceiptDataParts(data) {
  return Array.isArray(data) ? data : String(data).split("_");
}

export function getJwsFromReceiptData(data) {
  const raw = getRawReceiptDataParts(data);
  const suiteId = String(raw[1]).split("-")[0];

  const suite = suites[suiteId];
  if (suite == null) {
    throw new Error(`Suite ${suiteId} not supported`);
  }

  const header = { alg: suite.jwsSignatureAlgorithm };
  const payload = raw.slice(0, -1).join("_");
  const signature = raw[raw.length - 1];

  const jwsHeader = encodeBase64Url(JSON.stringify(header));
  const jwsPayload = encodeBase64Url(payload);
  const jwsSignature = base64ToBase64Url(signature);

  return jwsHeader + "." + jwsPayload + "." + jwsSignature;
}

export function decodeReceiptData(data) {
  const raw = getRawReceiptDataParts(data);

  if (raw.length < 13) {
    throw new Error(`Invalid receipt data: ${data}`);
  }

  const cashboxId = raw[2];
  const receiptNo = raw[3];
  const dateTime = new Date(raw[4]);

  const sumTotal = [
    5, // Normal (20%)
    6, // Ermaessigt1 (10%)
    7, // Ermaessigt2 (13%)
    8, // Null (0%)
    9, // Besonders (19%)
  ].map((i) => parseNumber(raw[i])).reduce((s, v) => (v += s), 0);

  const encryptedSalesCounter = raw[10];
  const certificateSerialNumber = raw[11];
  const previousSignatureHash = raw[12];

  return {
    cashboxId,
    receiptNo,
    dateTime,
    sumTotal,
    encryptedSalesCounter,
    certificateSerialNumber,
    previousSignatureHash,
  };
}

export async function getSignatureHashBase64(jws, algorithm = "SHA-256", numberOfBytesExtracted = 0) {
  // const { webcrypto: crypto } = require("node:crypto");
  let hashBytes = new Uint8Array(await crypto.subtle.digest(algorithm, jws));
  if (numberOfBytesExtracted > 0) {
    hashBytes = hashBytes.slice(0, numberOfBytesExtracted);
  }
  return btoa(String.fromCharCode(...hashBytes));
}

// -------------------------------------

const numberFormatDeAtLocale = Intl.NumberFormat("de-AT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export function parseNumber(number) {
  return parseFloat(number.split(".").join("_").split(",").join(".").split("_").join(","));
}

export function formatNumber(number) {
  return numberFormatDeAtLocale.format(number);
}

export function formatDateTime(dateTime) {
  return new Date(dateTime)
    .toLocaleString("de-AT", { day: "2-digit", month: "2-digit", year: "2-digit", hour: "2-digit", minute: "2-digit" })
    .replace(",", "");
}

export async function importSPKI(spki, alg = "ES256") {
  return await jose.importSPKI(spki, "ES256");
}
