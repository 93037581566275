import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { Observable } from "rxjs";

export interface BaseUrlPrefixMapping {
  prefix: string;
  baseUrl: string;
}

export const BASE_URL_PREFIX_MAPPING = new InjectionToken<BaseUrlPrefixMapping>("baseUrlPrefixMapping");

@Injectable()
export class BaseUrlPrefixInterceptor implements HttpInterceptor {
  private readonly _prefixMappings: [RegExp, string][] = [];

  public constructor(
    @Optional() @Inject(BASE_URL_PREFIX_MAPPING) prefixMappings: ReadonlyArray<BaseUrlPrefixMapping>, //
  ) {
    prefixMappings?.forEach((m) => {
      this._prefixMappings.push([this._getPrefixRegExp(m.prefix), this._getSanitizedBaseUrl(m.baseUrl)]);
    });
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = this.replacePrefixWithBaseUrl(req.url);
    const headers = req.headers;
    return next.handle(req.clone({ url, headers }));
  }

  public replacePrefixWithBaseUrl(url: string): string {
    let replaced: boolean;
    for (const [r, b] of this._prefixMappings) {
      const u = url.replace(r, () => {
        replaced = true;
        return b;
      });
      if (replaced) {
        return u;
      }
    }
    return url;
  }

  private _getPrefixRegExp(prefix: string) {
    return new RegExp(prefix + "://");
  }

  private _getSanitizedBaseUrl(baseUrl: string): string {
    return String(baseUrl).replace(/\/$/g, "") + "/";
  }
}
