import { Pipe, PipeTransform } from "@angular/core";

import { BaseUrlPrefixInterceptor } from "../interceptors/base-url-prefix";

@Pipe({
  name: "rsBaseUrl",
  pure: true,
})
export class BaseUrlPipe implements PipeTransform {
  public constructor(
    private _interceptor: BaseUrlPrefixInterceptor, //
  ) {}

  public transform(value: any, defaultUrl?: string) {
    return this._interceptor.replacePrefixWithBaseUrl(String(value ?? defaultUrl ?? ""));
  }
}
