import { Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ArVideoModalContent } from "../modals/ar-video/ar-video.modal";
import { winBackText, arTutorialDescription, pwaHintText } from "../texts";

@Component({
  templateUrl: "hint.page.html",
  host: { class: "flex" },
})
export class HintPage {
  public readonly text = winBackText;

  public readonly arTutorialDescription = arTutorialDescription;

  public readonly pwaHintText = pwaHintText;

  public constructor(private readonly _modal: NgbModal) {}

  //#region Modal

  public async openVideoModal(event: MouseEvent) {
    event?.stopPropagation();
    event?.preventDefault();

    const modalRef = this._modal.open(ArVideoModalContent, {
      //
      centered: true,
      scrollable: true,
      keyboard: true,
    });
    try {
      await modalRef.result;
    } catch {
      /* ignore */
    }
  }

  //#endregion
}
