import { Component, Input } from "@angular/core";

@Component({
  selector: "app-svg-symbol",
  template: `<svg class="flex flex-auto max-w-full max-h-full">
    <use [attr.xlink:href]="'#' + name"></use>
  </svg>`,
  host: { class: "inline-flex place-self-stretch" },
})
export class SvgSymbolComponent {
  @Input()
  public name: string;
}
