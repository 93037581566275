import { Component, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { INewsletterSubscribeResponse } from "../../interfaces";

import { NewsletterApiService } from "../../services/newsletter.api";
import { $formatErrors } from "../../utilities/error";

@Component({
  selector: "app-newsletter-form",
  templateUrl: "newsletter-form.html",
})
export class NewsletterFormComponent {
  //#region Properties

  public readonly self = this;

  @ViewChild("successModal", { read: TemplateRef })
  public successModalTemplateRef: TemplateRef<{ modal: NgbModalRef }>;

  public readonly form = this._fb.group({
    mail: [
      "",
      [
        Validators.required,
        Validators.maxLength(80),
        // Validators.pattern(/^[\w][a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/),
      ],
    ],
  });

  public readonly acceptDataPrivacyStatement = new FormControl(false);

  //#endregion

  //#region Constructor

  public constructor(
    private readonly _fb: FormBuilder, //
    private readonly _api: NewsletterApiService,
    private readonly _modal: NgbModal,
  ) {}

  //#endregion

  //#region Form Methods

  public async submit() {
    this.acceptDataPrivacyStatement.markAllAsTouched();
    this.form.markAllAsTouched();

    const { mail } = this.form.controls;

    if (mail.errors?.["api"]) {
      mail.setErrors(null);
      this.form.updateValueAndValidity();
    }

    const copAccepted = !!this.acceptDataPrivacyStatement.value;
    if (!copAccepted || this.form.invalid) {
      this._scrollFormIntoView();
      return;
    }

    const value = this.form.value;
    this.acceptDataPrivacyStatement.disable();
    mail.disable();

    try {
      await this._subscribe(String(value?.mail ?? ""));
    } catch (err /* : HttpErrorResponse */) {
      if ((err as any).status != 200) {
        const { errors } = ((err as any).error as INewsletterSubscribeResponse) ?? ({} as any);
        this.acceptDataPrivacyStatement.enable();
        mail.enable();
        mail.updateValueAndValidity();
        mail.setErrors({
          api: errors.mail[0] ?? "Ein unerwarteter Fehler ist aufgetreten, bitte versuchen Sie es erneut.",
        });
        mail.markAsDirty();
        this._scrollFormIntoView();
        return;
      }
    }

    this.acceptDataPrivacyStatement.setValue(false);
    this.acceptDataPrivacyStatement.markAsUntouched();
    this.acceptDataPrivacyStatement.markAsPristine();

    this.acceptDataPrivacyStatement.enable();
    mail.enable();
    mail.setValue("");
    this.form.markAsUntouched();
    this.form.markAsPristine();

    await this._openSuccessModal();
  }

  //#endregion

  //#region Newsletter

  private async _subscribe(mail: string) {
    await this._api.subscribe({ mail }).toPromise();
  }

  private async _openSuccessModal() {
    const { result } = this._modal.open(this.successModalTemplateRef, {
      //
      centered: true,
      size: "xl",
      scrollable: true,
      windowClass: "newsletter-success-modal",
      keyboard: true,
    });
    try {
      return await result;
    } catch (reason) {
      return reason;
    }
  }

  //#endregion

  //#region Pipe Functions

  public readonly $formatErrors = $formatErrors;

  //#endregion

  //#region Utility Methods

  private _scrollFormIntoView() {
    const winForm = document.getElementById("app-newsletter-form-anchor");
    winForm?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  //#endregion
}
