import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-add-to-home-screen",
  templateUrl: "add-to-home-screen.html",
})
export class AddToHomeScreenComponent {
  @Output()
  public readonly onClose = new EventEmitter();
}
