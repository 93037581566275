import { LuminanceSource } from "@zxing/library";
import BinaryBitmap from "@zxing/library/esm/core/BinaryBitmap";
import HybridBinarizer from "@zxing/library/esm/core/common/HybridBinarizer";
import DecodeHintType from "@zxing/library/esm/core/DecodeHintType";
import MultipleBarcodeReader from "@zxing/library/esm/core/multi/MultipleBarcodeReader";
import MultiFormatOneDReader from "@zxing/library/esm/core/oned/MultiFormatOneDReader";
import QRCodeReader from "@zxing/library/esm/core/qrcode/QRCodeReader";
import Reader from "@zxing/library/esm/core/Reader";

import { BarcodeDetectorOptions, BarcodeFormat, DetectedBarcode } from "./interfaces";
import { ImageDataLuminanceSource } from "./zxing/image-data-luminance-source";
import MultiReader from "./zxing/multi-reader";
import { resultToDetectedBarcode } from "./zxing/shim";

const supportedFormats = [
  BarcodeFormat.qr_code,
  BarcodeFormat.ean_13,
  BarcodeFormat.ean_8,
  BarcodeFormat.code_39,
  BarcodeFormat.code_128,
  BarcodeFormat.upc_a,
  BarcodeFormat.upc_e,
];

function checkOptionsFormats(options: BarcodeDetectorOptions) {
  return (
    options?.formats == null || (options.formats.length > 0 && options.formats.every(f => f === BarcodeFormat.unknown))
  );
}

function setupReaders(formats?: BarcodeFormat[]) {
  if (!formats?.length) {
    return [new QRCodeReader(), new MultiFormatOneDReader(new Map([[DecodeHintType.TRY_HARDER, true]]))];
  }
  const readers: Reader[] = [];
  if (formats.includes(BarcodeFormat.qr_code)) {
    readers.push(new QRCodeReader());
  }
  if (
    [
      BarcodeFormat.ean_13,
      BarcodeFormat.ean_8,
      BarcodeFormat.code_39,
      BarcodeFormat.code_128,
      BarcodeFormat.upc_a,
      BarcodeFormat.upc_e,
    ].some(b => formats.includes(b))
  ) {
    readers.push(new MultiFormatOneDReader(new Map([[DecodeHintType.TRY_HARDER, true]])));
  }
  return readers;
}

/**
 * Barcode Detector
 * @see https://wicg.github.io/shape-detection-api/#barcode-detection-api
 * @see https://web.dev/shape-detection/
 * @see https://github.com/yellowdoge/shapedetection-polyfill
 */
export class BarcodeDetector {
  //#region Properties
  private readonly _reader: Reader & MultipleBarcodeReader;
  //#endregion

  //#region Static Methods

  public static getSupportedFormats(): Promise<BarcodeFormat[]> {
    return Promise.resolve(supportedFormats);
  }

  //#endregion

  //#region Constructor

  public constructor(private readonly _options: BarcodeDetectorOptions = {}) {
    // if (!checkOptionsFormats(_options)) {
    //   throw new TypeError("Invalid formats");
    // }
    this._reader = new MultiReader(setupReaders(_options?.formats));
  }

  //#endregion

  //#region Detector

  /**
   * @see https://wicg.github.io/shape-detection-api/#dom-barcodedetector-detect
   */
  public async detect(image: ImageBitmapSource): Promise<DetectedBarcode[]> {
    const bitmap = this._getBinaryBitmap(image);
    return await this._detectInBinaryBitmap(bitmap);
  }

  private _detectInBinaryBitmap(bitmap: BinaryBitmap): Promise<DetectedBarcode[]> {
    const detectedBarcodes: DetectedBarcode[] = [];
    let tryAgain: boolean;
    do {
      try {
        const results = this._reader.decodeMultiple(bitmap);
        tryAgain = results?.length > 0;
        results?.forEach(r => {
          const barcode = resultToDetectedBarcode(r);
          detectedBarcodes.push(barcode);
          if (tryAgain && !this._unsetBitsOfDetectedBarcode(bitmap, barcode)) {
            tryAgain = false;
          }
        });
      } catch {
        tryAgain = false;
      }
    } while (tryAgain);
    return Promise.resolve(detectedBarcodes);
  }

  //#endregion

  //#region Helper Methods

  private printy(imageData: ImageData) {
    const canvas = document.createElement("canvas");
    canvas.width = imageData.width;
    canvas.height = imageData.height;

    const context = canvas.getContext("2d") as CanvasRenderingContext2D;
    context.putImageData(imageData, 0, 0);

    const dataURL = canvas.toDataURL("image/png");

    console.log(
      "%c ",
      [
        "padding-left: " + canvas.width + "px;",
        "padding-top: " + canvas.height + "px;",
        `background-image: url("${dataURL}");`,
        "background-position: top center;",
        "background-repeat: no-repeat;",
        "background-site: contain;",
        "position: absolute"
      ].join(" "),
    );
  }

  private lumiToImageData(lumi: LuminanceSource) {
    const l = lumi.getMatrix();
    const b = new Uint8ClampedArray(l.length * 4);
    // const v = new Uint32Array(b.buffer);
    for (let i = 0; i < l.length; i++) {
      // v[i] = (l[i] * (2 ** 24)) + (l[i] * (2 ** 16)) + (l[i] * (2 ** 8));
      // v[i] = Number.MAX_SAFE_INTEGER;
      // // v[i] = l[i] << 16 | 0 << 8 | 0;
      b[i * 4 + 0] = l[i];
      b[i * 4 + 1] = l[i];
      b[i * 4 + 2] = l[i];
      b[i * 4 + 3] = 255;
    }
    return new ImageData(b, lumi.getWidth(), lumi.getHeight());
  }

  private sharpen(src: ImageData, mix = 0.5) {
    // const weights = [0, -1, 0, -1, 5, -1, 0, -1, 0];
    const weights = [-1, -1, -1, -1, 9, -1, -1, -1, -1];
    const katet = Math.round(Math.sqrt(weights.length));
    const half = (katet * 0.5) | 0;
    const w = src.width;
    const h = src.height;
    const imageData = new ImageData(w, h);
    const dstBuff = imageData.data;
    const srcBuff = src.data;
    let x, sx, sy, r, g, b, a, dstOff, srcOff, wt, cx, cy, scy, scx;
    let y = h;

    while (y--) {
        x = w;
        while (x--) {
            sy = y;
            sx = x;
            dstOff = (y * w + x) * 4;
            r = 0;
            g = 0;
            b = 0;
            a = 0;

            for (cy = 0; cy < katet; cy++) {
                for (cx = 0; cx < katet; cx++) {
                    scy = sy + cy - half;
                    scx = sx + cx - half;

                    if (scy >= 0 && scy < h && scx >= 0 && scx < w) {
                        srcOff = (scy * w + scx) * 4;
                        wt = weights[cy * katet + cx];

                        r += srcBuff[srcOff] * wt;
                        g += srcBuff[srcOff + 1] * wt;
                        b += srcBuff[srcOff + 2] * wt;
                        a += srcBuff[srcOff + 3] * wt;
                    }
                }
            }

            dstBuff[dstOff] = r * mix + srcBuff[dstOff] * (1 - mix);
            dstBuff[dstOff + 1] = g * mix + srcBuff[dstOff + 1] * (1 - mix);
            dstBuff[dstOff + 2] = b * mix + srcBuff[dstOff + 2] * (1 - mix);
            dstBuff[dstOff + 3] = srcBuff[dstOff + 3];
        }
    }
    return imageData;
  }

  private _getBinaryBitmap(image: ImageBitmapSource) {
    if (image instanceof ImageData) {
      // const a = this.sharpen(image, 0.9);
      // const l = new ImageDataLuminanceSource(a);

      // const a = new ImageDataLuminanceSource(image);
      // const b = this.sharpen(this.lumiToImageData(a), 0.1);
      // const l = new ImageDataLuminanceSource(b);

      const l = new ImageDataLuminanceSource(image);

      // this.printy(b);
      // this.printy(this.lumiToImageData(l));
      return new BinaryBitmap(new HybridBinarizer(l));
    }
    throw new TypeError("Unsupported image source");
  }

  private _getCornerPointsMinMax(barcode: DetectedBarcode) {
    const xs = barcode.cornerPoints.map(p => Math.floor(p.x));
    const ys = barcode.cornerPoints.map(p => Math.floor(p.y));
    return {
      x: { min: Math.min(...xs), max: Math.max(...xs) },
      y: { min: Math.min(...ys), max: Math.max(...ys) },
    };
  }

  private _unsetBitsOfDetectedBarcode(bitmap: BinaryBitmap, barcode: DetectedBarcode) {
    const mm = this._getCornerPointsMinMax(barcode);
    if (barcode.format !== BarcodeFormat.qr_code) {
      return false;
    }
    const matrix = bitmap.getBlackMatrix();
    for (let y = mm.y.min; y <= mm.y.max; y++) {
      for (let x = mm.x.min; x <= mm.x.max; x++) {
        matrix.unset(x, y);
      }
    }
    return true;
  }

  //#endregion
}
