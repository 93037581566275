import { CommonModule, registerLocaleData } from "@angular/common";
import { HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import localeDeAt from "@angular/common/locales/de-AT";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { FaConfig, FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveComponentModule } from "@ngrx/component";
import { BaseUrlPrefixInterceptor, BASE_URL_PREFIX_MAPPING, RsNgCommonModule, RsNgHttpModule } from "@resee/ng-common";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { IMaskModule } from "angular-imask";
import { NgProgressModule } from "ngx-progressbar";
import { NgProgressRouterModule } from "ngx-progressbar/router";
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from "ngx-swiper-wrapper";

import { AppRoot } from "./app.root";
import { routes } from "./app.routes";
import { AddToHomeScreenComponent } from "./components/add-to-home-screen/add-to-home-screen";
import { AdventCalendarComponent } from "./components/advent-calendar/advent-calendar";
import { ContainerWithFloorComponent } from "./components/container-with-floor";
import { CountdownComponent } from "./components/countdown/coutdown";
import { FooterComponent } from "./components/footer/footer";
import { HeaderComponent } from "./components/header/header";
import { NewsletterFormComponent } from "./components/newsletter-form/newsletter-form";
import { RenderingContainerComponent } from "./components/rendering-container/rendering-container";
import { RundUmWeihnachtenComponent } from "./components/rund-um-weihnachten";
import { ScannerComponent } from "./components/scanner/scanner";
import { SvgSymbolComponent } from "./components/svg-symbol";
import { SvgSymbolDefinitionsComponent } from "./components/svg-symbol-definitions";
import { WinFormComponent } from "./components/win-form/win-form";
import { environment } from "./environment";
import { fallbackIcon, icons } from "./fa-icons";
import { DayCanActivateGuard } from "./guards/day";
import { GameStateCanActivateGuard } from "./guards/game-state";
import { WinRedirectCanActivateGuard } from "./guards/win-redirect";
import { DefaultLayout } from "./layouts/default/default";
import { ArVideoModalContent } from "./modals/ar-video/ar-video.modal";
import { PrizeDetailModalContent } from "./modals/prize-detail/prize-detail";
import { ConditionsOfParticipationPage } from "./pages/conditions-of-participation.page";
import { DayPage } from "./pages/day.page";
import { HintPage } from "./pages/hint.page";
import { HomePage } from "./pages/home.page";
import { NotFoundPage } from "./pages/not-found.page";
import { PrizesPage } from "./pages/prizes.page";
import { DistancePipe } from "./pipes/distance.pipe";
import { AdventCalendarApiService } from "./services/advent-calendar.api";
import { GameInfoService, GameInfoStore } from "./services/game-info";
import { LocalStorageService } from "./services/local-storage";
import { NewsletterApiService } from "./services/newsletter.api";

registerLocaleData(localeDeAt);

const SERVICE_WORKER_SCRIPT = "service-worker.js";

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: "auto",
  centeredSlides: true,
  grabCursor: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  flipEffect: {
    slideShadows: false,
  },
};

const PAGES = [
  HomePage, //
  DayPage,
  HintPage,
  ConditionsOfParticipationPage,
  NotFoundPage,
  PrizesPage,
  // ArViewPage,
];

const COMPONENTS = [
  AddToHomeScreenComponent,
  AdventCalendarComponent,
  ContainerWithFloorComponent,
  CountdownComponent,
  FooterComponent,
  HeaderComponent,
  NewsletterFormComponent,
  PrizeDetailModalContent,
  RenderingContainerComponent,
  SvgSymbolComponent,
  SvgSymbolDefinitionsComponent,
  WinFormComponent,
  ArVideoModalContent,
  RundUmWeihnachtenComponent,
  ScannerComponent,
];

// -------------------------------------

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RsNgCommonModule,
    RsNgHttpModule,
    ReactiveComponentModule,
    RouterModule.forRoot(routes, {
      enableTracing: !!environment.enableTracing,
      scrollPositionRestoration: "enabled",
    }),
    NgProgressModule.withConfig({
      color: "#ffffff",
      spinner: false,
      meteor: false,
      fixed: false,
    }),
    NgProgressRouterModule,
    ServiceWorkerModule.register(SERVICE_WORKER_SCRIPT, {
      enabled: !!environment.enableServiceWorker,
    }),
    NgbTooltipModule,
    SwiperModule,
    IMaskModule,
    ZXingScannerModule,
  ],

  declarations: [
    AppRoot, //
    DefaultLayout,
    ...PAGES,
    ...COMPONENTS,
    DistancePipe,
  ],

  providers: [
    { provide: LOCALE_ID, useValue: "de-AT" },
    HttpClient,
    AdventCalendarApiService,
    NewsletterApiService,
    GameStateCanActivateGuard,
    DayCanActivateGuard,
    WinRedirectCanActivateGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: FaConfig, library: FaIconLibrary) => {
        return () => {
          config.defaultPrefix = "fas" as IconPrefix;
          config.fallbackIcon = fallbackIcon;
          config.fixedWidth = true;
          library.addIcons(...(icons as any[]));
        };
      },
      deps: [FaConfig, FaIconLibrary],
      multi: true,
    },
    BaseUrlPrefixInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlPrefixInterceptor,
      multi: true,
    },
    {
      provide: BASE_URL_PREFIX_MAPPING,
      useValue: {
        prefix: "api",
        baseUrl: environment.config.apiBaseUrlPrefix,
      },
      multi: true,
    },
    {
      provide: BASE_URL_PREFIX_MAPPING,
      useValue: {
        prefix: "resource",
        baseUrl: environment.config.apiBaseUrlPrefix.replace(/\/api\/$/, "/"),
      },
      multi: true,
    },
    GameInfoService,
    GameInfoStore,
    LocalStorageService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
  ],

  bootstrap: [AppRoot],
})
export class AppModule {}
