import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { filter, map } from "rxjs/operators";

import { GameInfoService, GameStatus } from "../services/game-info";

@Injectable()
export class GameStateCanActivateGuard implements CanActivate {
  public constructor(private _router: Router, private readonly _gameInfo: GameInfoService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._gameInfo.status$ //
      .pipe(filter((s) => s !== GameStatus.Undefined))
      .pipe(map((s) => s === GameStatus.Running || this._router.createUrlTree(["/"])));
  }
}
