import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { addDays } from "date-fns";
import { filter, map } from "rxjs/operators";

import { GameInfoService } from "../services/game-info";

const decemberFirst = new Date("2024-12-01T00:00:00+01:00");

@Injectable()
export class DayCanActivateGuard implements CanActivate {
  public constructor(private _router: Router, private readonly _gameInfo: GameInfoService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const day = Number(route.params.day);
    const date = addDays(decemberFirst, day - 1);

    return this._gameInfo.currentTime$ //
      .pipe(filter((currentTime) => !!currentTime))
      .pipe(map((currentTime) => date <= currentTime || this._router.createUrlTree(["/"])));
  }
}
