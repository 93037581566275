import { AbstractControl } from "@angular/forms";

export function getErrorMessageForKey(
  key: string,
  errors: AbstractControl["errors"],
  message?: { [key: string]: string } | string,
) {
  const error = typeof errors[key] === "string" ? errors[key] : null;
  const msg = typeof message === "object" ? message[key] ?? message.invalid : message;
  return error ?? msg ?? `<${key}>`;
}

export function $formatErrors(errors: AbstractControl["errors"], message?: { [key: string]: string } | string) {
  if (!errors) {
    return null;
  }
  const e = ["required", "api"] //
    .filter((k) => errors[k])
    .map((k) => getErrorMessageForKey(k, errors, message));
  return e[0] ?? getErrorMessageForKey("invalid", errors, message);
}
