import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({ name: "rsTrustHtml", pure: true })
export class TrustHtmlPipe implements PipeTransform {
  public constructor(private readonly _sanitizer: DomSanitizer) {}

  public transform(value: any): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(value);
  }
}
