import ArgumentException from "@zxing/library/esm/core/ArgumentException";
import BinaryBitmap from "@zxing/library/esm/core/BinaryBitmap";
import DecodeHintType from "@zxing/library/esm/core/DecodeHintType";
import MultipleBarcodeReader from "@zxing/library/esm/core/multi/MultipleBarcodeReader";
import NotFoundException from "@zxing/library/esm/core/NotFoundException";
import Reader from "@zxing/library/esm/core/Reader";
import Result from "@zxing/library/esm/core/Result";

export default class MultiReader implements Reader, MultipleBarcodeReader {
  private readonly readers: Reader[];

  public constructor(readers: Reader[]) {
    if (!readers?.length) {
      throw new ArgumentException("Specify at least one reader");
    }
    this.readers = [...readers];
  }

  public decode(image: BinaryBitmap): Result {
    return this.decodeMultiple(image)[0];
  }

  public decodeMultiple(image: BinaryBitmap, hints?: Map<DecodeHintType, any>): Result[] {
    const results = [];
    for (const reader of this.readers) {
      try {
        const result = reader.decode(image, hints);
        if (result) {
          results.push(result);
        }
      } catch {
        continue;
      }
    }
    if (!results.length) {
      throw new NotFoundException("No barcodes detected");
    }
    return results;
  }

  public reset() {
    for (const reader of this.readers) {
      reader.reset();
    }
  }
}
