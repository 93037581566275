import ZxingBarcodeFormat from "@zxing/library/esm/core/BarcodeFormat";
import Result from "@zxing/library/esm/core/Result";

import { BarcodeFormat, DetectedBarcode } from "../interfaces";

export function getBarcodeFormat(result: Result): BarcodeFormat {
  switch (result.getBarcodeFormat()) {
    case ZxingBarcodeFormat.QR_CODE: {
      return BarcodeFormat.qr_code;
    }
    case ZxingBarcodeFormat.EAN_13: {
      return BarcodeFormat.ean_13;
    }
    default: {
      return BarcodeFormat.unknown;
    }
  }
}

export function getCornerPoints(result: Result): DetectedBarcode["cornerPoints"][0][] {
  return result.getResultPoints().map(p => ({ x: p.getX(), y: p.getY() }));
}

export function resultToDetectedBarcode(result: Result) {
  return {
    boundingBox: undefined,
    rawValue: result.getText(),
    format: getBarcodeFormat(result),
    cornerPoints: getCornerPoints(result),
  } as DetectedBarcode;
}
