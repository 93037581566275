import { Component } from "@angular/core";

@Component({
  host: { class: "flex place-items-center place-content-center text-white" },
  template: `<!-- <template> -->
    <app-container-with-floor style="--top-bar-height: 4rem;">
      <div hero-area class="xl:container place-items-center lg:p-64 flex flex-auto p-32 z-10">
        <h1 class="lg:text-4xl text-3xl font-bold uppercase m-auto">Seite nicht gefunden</h1>
      </div>
    </app-container-with-floor>
    <!-- </template> -->`,
})
export class NotFoundPage {}
