import { Component } from "@angular/core";
import { GameInfoService } from "../services/game-info";

@Component({
  template: `<!-- <template> -->
    <div class="flex flex-row min-h-full bg-blue lg:p-10" style="max-width: 100vw; overflow-wrap: break-word;">
      <div class="flex-auto overflow-auto p-40 bg-white xl:container lg:rounded-sm animate__animated animate__fadeIn">
        <div
          *ngIf="gameInfo.copMessage$ | async as message"
          [innerHTML]="message?.body | rsTrustHtml"
          class="prose max-w-none"
        ></div>
        <div class="button-row-action-spacer"></div>
      </div>
    </div>
    <!-- </template> -->`,
})
export class ConditionsOfParticipationPage {
  public constructor(public readonly gameInfo: GameInfoService) {}
}
