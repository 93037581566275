import { environmentConfig, IAppEnvironment } from "./environment.base";

const API_BASE_URL_PREFIX = "/api/";

export const environment: IAppEnvironment = {
  version: __VERSION__,
  production: false,
  enableTracing: false,
  enableServiceWorker: false,
  config: environmentConfig({
    apiBaseUrlPrefix: API_BASE_URL_PREFIX,
    enableSnow: true,
  }),
};
