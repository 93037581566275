import { NgModule } from "@angular/core";

import { FuncPipe } from "./pipes/func.pipe";
import { JoinPipe } from "./pipes/join.pipe";
import { TrustHtmlPipe } from "./pipes/trust-html.pipe";
import { TrustUrlPipe } from "./pipes/trust-url.pipe";
import { WrapUrlPipe } from "./pipes/wrap-url.pipe";

export * from "./pipes/func.pipe";
export * from "./pipes/join.pipe";
export * from "./pipes/trust-html.pipe";
export * from "./pipes/wrap-url.pipe";
export * from "./pipes/trust-url.pipe";

const PIPES = [
  FuncPipe, //
  JoinPipe,
  TrustHtmlPipe,
  TrustUrlPipe,
  WrapUrlPipe,
];

@NgModule({
  imports: [],

  declarations: [
    ...PIPES, //
  ],

  providers: [],

  exports: [
    ...PIPES, //
  ],
})
export class RsNgCommonModule {}
