import { Component, ContentChild, ElementRef, Renderer2, ViewChild } from "@angular/core";

@Component({
  selector: "app-rendering-container",
  templateUrl: "./rendering-container.html",
})
export class RenderingContainerComponent {
  //#region Layers

  @ViewChild("layerWorldEnvironment", { static: true })
  public layerWorldEnvironment: ElementRef<HTMLElement>;

  @ViewChild("layerLocbaContent", { static: true })
  public layerLocbaContent: ElementRef<HTMLElement>;

  @ContentChild("mediaElement")
  public worldMediaElementRef: ElementRef<HTMLElement>;

  //#endregion

  //#region Constructor

  public constructor(
    private readonly element: ElementRef, //
    private readonly renderer: Renderer2,
  ) {}

  //#endregion

  //#region Helper Methods

  public appendToLayer(layer: ElementRef<HTMLElement>, element: HTMLElement) {
    this.renderer.appendChild(layer.nativeElement, element);
  }

  public createElementFromName(name: string) {
    return this.renderer.createElement(name);
  }

  //#endregion
}
