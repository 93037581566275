import { Component } from "@angular/core";

@Component({
  selector: "app-svg-symbol-definitions",
  host: { style: "visibility: hidden; width: 0; height: 0; position: absolute;" },
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="visibility: hidden; width: 0; height: 0; position: absolute;"
  >
    <!-- Icons / Arrows -->

    <symbol id="chevron-up" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M19.788 16.452a.65.65 0 01-.92 0l-6.88-6.88L5.11 16.45a.651.651 0 01-.92-.92l7.325-7.325c.005-.004.005-.01.01-.015a.653.653 0 01.463-.19.65.65 0 01.463.19c.003.005.005.011.01.015l7.327 7.328a.65.65 0 010 .919z"
      />
    </symbol>

    <symbol id="arrow-left" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M8.47 19.31a.649.649 0 01-.918 0L.734 12.492c-.014-.01-.03-.016-.044-.029a.649.649 0 010-.918l.005-.004.002-.003.003-.003L7.545 4.69a.649.649 0 11.918.919l-5.737 5.737H19.85a.65.65 0 010 1.3H2.725l5.745 5.745a.649.649 0 010 .919z"
      />
    </symbol>

    <symbol id="info-i" viewBox="0 0 6.8 11.3">
      <path
        fill="currentColor"
        d="M2,9.8C2.1,10,2.3,10,2.4,10C3,10,3.6,9.7,3.9,9.2c0.3-0.4,0.6-0.9,0.8-1.4L6,4.9 c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.3-0.1,0.5-0.1L6.2,5.8L5.2,8C5,8.6,4.7,9.1,4.4,9.7c-0.6,1-1.6,1.6-2.7,1.7 c-0.5,0-1-0.2-1.3-0.7C0.1,10.2,0,9.8,0,9.3c0-0.6,0.1-1.1,0.2-1.7c0.1-0.5,0.3-1,0.5-1.7C1,5.1,1.1,4.6,1.2,4.5 c0.1-0.4,0.2-0.7,0.2-1.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0C1.7,3,1.9,3,2,3c0.2,0,0.5,0,0.7,0.1 c0.6,0.1,0.8,0.3,0.8,0.5c0,0.4-0.1,0.8-0.3,1.1C3.2,4.9,3,5.4,2.7,6.2c-0.4,1-0.7,2-0.8,3.1C1.9,9.5,1.9,9.7,2,9.8z M4.5,1.2 c0,0.2-0.1,0.4-0.1,0.6C4.2,2.1,3.9,2.3,3.6,2.4C3.5,2.5,3.3,2.5,3.2,2.5c-0.1,0-0.3,0-0.4-0.1C2.5,2.3,2.3,2.1,2.2,1.8 c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0-0.4,0.1-0.6C2.4,0.5,2.7,0.2,3,0.1C3.1,0,3.2,0,3.4,0c0.2,0,0.3,0,0.4,0.1c0.3,0.1,0.5,0.4,0.6,0.7 C4.4,0.9,4.5,1.1,4.5,1.2L4.5,1.2z"
      />
    </symbol>

    <!-- Utilities -->

    <symbol id="ratio-spacer-3-4" viewBox="0 0 3 4">
      <rect x="0" y="0" width="3" height="4" />
    </symbol>

    <!-- Numbers -->

    <symbol id="number-1" viewBox="0 0 3.3 23.2">
      <path
        fill="currentColor"
        d="M0,0.9c0-0.3,0.2-0.7,0.5-0.8C0.8,0,1.1,0,1.4,0c0.3,0,0.7,0.2,1,0.3c0.3,0.2,0.4,0.3,0.5,0.5 c0,1.9,0.1,3.7,0.1,5.5c0,1.8,0.1,3.5,0.1,5.3c0,1.7,0.1,3.5,0.1,5.3c0,1.8,0.1,3.6,0.1,5.5c0,0.2-0.2,0.4-0.5,0.5 c-0.3,0.1-0.7,0.2-1,0.3c-0.3,0-0.7,0-1-0.1c-0.3-0.1-0.4-0.3-0.4-0.6v-0.6L0,0.9z"
      />
    </symbol>

    <symbol id="number-2" viewBox="0 0 11 19.8">
      <path
        fill="currentColor"
        d="M1.6,4.5C1.4,4.8,1.1,4.9,0.8,4.9C0.5,4.8,0.4,4.6,0.4,4.2c0.2-0.8,0.7-1.5,1.3-2.1 C2.3,1.5,3,1,3.8,0.6C4.7,0.3,5.5,0.1,6.4,0c0.9-0.1,1.8,0,2.6,0.3c0.5,0.2,1,0.4,1.3,0.8C10.7,1.5,10.9,2,11,2.6 c0.1,1.1-0.1,2.3-0.6,3.3c-0.6,1.2-1.2,2.4-2,3.5c-0.8,1.2-1.6,2.3-2.5,3.5C5,14,4.3,15.1,3.6,16.3c-0.3,0.5-0.5,1.1-0.5,1.6 c0.1,0.4,0.5,0.6,1.3,0.6c0.9,0,1.9-0.1,2.8-0.2l2.8-0.4c0.4,0.1,0.6,0.2,0.6,0.5c0,0.3-0.2,0.6-0.4,0.7c-1.7,0.3-3,0.4-4.1,0.5 c-1,0.1-1.9,0.1-2.5,0.1c-0.6,0-1.1,0-1.5,0c-0.3,0-0.6-0.1-0.9-0.1C1,19.5,0.6,19.3,0.4,19c-0.3-0.3-0.4-0.8-0.4-1.3 c0.1-0.8,0.4-1.5,0.8-2.1c0.4-0.8,1-1.5,1.6-2.4c0.6-0.8,1.3-1.7,1.9-2.6C5,9.7,5.6,8.8,6.2,7.9c0.6-0.9,1-1.8,1.4-2.7 c0.3-0.9,0.5-1.8,0.5-2.7C8,1.9,7.5,1.5,6.9,1.4C6.2,1.3,5.5,1.3,4.8,1.6C4.1,1.8,3.4,2.2,2.7,2.7C2.1,3.1,1.7,3.8,1.6,4.5z"
      />
    </symbol>

    <symbol id="number-3" viewBox="0 0 10 18.3">
      <path
        fill="currentColor"
        d="M1.2,1.5C0.7,1.4,0.5,1.2,0.5,0.9s0.2-0.5,0.6-0.7c1.2-0.1,2.1-0.1,2.9-0.1S5.2,0,5.7,0 c0.4,0,0.8,0,1.1,0h0.8C8.4,0,9,0.2,9.1,0.6C9.3,1,9.3,1.5,9,1.9C8.6,2.6,8.2,3.2,7.7,3.8C7.1,4.4,6.5,5,5.9,5.6 C5.3,6.2,4.7,6.7,4.3,7.2C3.8,7.6,3.6,7.9,3.6,8c0.3,0.4,0.6,0.7,1,1c0.4,0.3,1,0.7,1.5,1c0.6,0.4,1.1,0.7,1.7,1.1 c0.5,0.3,1,0.8,1.4,1.2c0.4,0.4,0.7,0.9,0.8,1.5c0.1,0.6,0,1.3-0.2,1.8c-0.5,0.8-1.2,1.5-2,1.9c-1,0.5-2,0.8-3.1,0.8 c-1,0.1-2-0.1-3-0.6c-0.9-0.4-1.5-1.3-1.6-2.2c0-0.3,0.2-0.6,0.5-0.6c0.3-0.1,0.5,0.1,0.7,0.4c0,0.5,0.2,0.9,0.6,1.2 C2.1,16.8,2.5,17,3,17.1c0.8-0.1,1.6-0.3,2.3-0.6c0.6-0.2,1.2-0.6,1.6-1.2c0.4-0.5,0.4-1.2,0-1.7c-0.5-0.7-1-1.2-1.7-1.7 c-0.7-0.6-1.5-1.1-2.3-1.6C2.3,9.9,1.7,9.5,1.1,8.9C0.9,8.8,0.8,8.6,0.7,8.4c-0.1-0.2,0-0.4,0.2-0.7c0.4-0.4,0.9-0.9,1.4-1.5 c0.5-0.5,1.1-1.1,1.6-1.6c0.5-0.5,0.9-1.1,1.4-1.7c0.4-0.5,0.6-1.1,0.8-1.7L3.6,1.3C2.8,1.4,2,1.4,1.2,1.5z"
      />
    </symbol>

    <symbol id="number-4" viewBox="0 0 12.2 23.5">
      <path
        fill="currentColor"
        d="M11.5,14.9c0.2,0,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0,0.3-0.1,0.4 c-0.1,0.1-0.3,0.2-0.4,0.2c-0.3,0-0.6,0-1.1,0c-0.5,0-1,0-1.6,0.1c0.1,1.1,0.1,2.2,0.1,3.3c0,1.1-0.1,2.2-0.2,3.3 c-0.1,0.3-0.3,0.6-0.6,0.6c-0.3,0.1-0.7,0-1,0c-0.3-0.1-0.6-0.3-0.9-0.5c-0.2-0.1-0.3-0.4-0.3-0.7c0.1-1,0.1-2,0.1-3 c0-1-0.1-2-0.1-3c-0.1,0-0.5,0-1.2,0c-0.7,0-1.4-0.1-2.1-0.2c-0.7-0.1-1.3-0.3-1.9-0.6c-0.5-0.2-0.7-0.6-0.5-1 c0.3-0.7,0.6-1.3,1.1-1.9c0.5-0.7,1-1.3,1.5-2c0.5-0.7,1-1.4,1.5-2.1c0.4-0.6,0.8-1.3,1-2C5.1,5.4,5,4.5,4.8,3.7 C4.7,2.9,4.8,2.1,5,1.4C5.1,1,5.4,0.6,5.7,0.3C5.9,0.1,6.3,0,6.6,0c0.3,0,0.6,0.1,0.9,0.3c0.3,0.2,0.6,0.5,0.7,0.8 C8.4,1.6,8.6,2,8.6,2.4c0,0.5,0,0.9,0,1.4C8.5,4.3,8.4,4.7,8.3,5.2C8.2,5.6,8.1,6,8,6.4c0.1,1.4,0.3,2.9,0.4,4.3s0.3,2.9,0.4,4.3 c0.7,0,1.3,0,1.8,0C11.1,14.9,11.4,14.9,11.5,14.9z M1.3,14.5c0.5,0.2,1,0.4,1.5,0.3c0.9,0.1,1.9,0.1,3,0.1c-0.1-1-0.2-2-0.3-3 S5.3,9.8,5.2,8.7c-0.3,0.5-0.6,1-1,1.5c-0.4,0.5-0.8,1.1-1.2,1.6s-0.7,1-1.1,1.5C1.7,13.7,1.5,14.1,1.3,14.5z"
      />
    </symbol>

    <!-- Social Buttons -->

    <symbol id="social-btn-fb" viewBox="0 0 1024 1024">
      <path
        fill="#1977f3"
        d="M1024,512C1024,229.25,794.75,0,512,0S0,229.25,0,512c0,255.53,187.2,467.36,432,505.81V660H302V512H432V399.17C432,270.87,508.47,200,625.4,200c56,0,114.63,10,114.63,10V336H675.44C611.86,336,592,375.41,592,416V512H734L711.3,660H592v357.78C836.8,979.43,1024,767.6,1024,512Z"
      />
      <path
        fill="#fefefe"
        d="M711.3,660,734,512H592V416c0-40.46,19.8-80,83.45-80H740V210s-58.61-10-114.63-10C508.47,200,432,270.8,432,399.17V512H302V660H432v357.78a514.38,514.38,0,0,0,80,6.19,523.09,523.09,0,0,0,80-6.19V660Z"
      />
    </symbol>

    <symbol id="social-btn-yt" viewBox="0 0 1024 1024">
      <path
        fill="#ff0000"
        d="M512,0C229.2,0,0,229.2,0,512s229.2,512,512,512s512-229.2,512-512S794.8,0,512,0z M787.2,658.1 c-6.7,24.6-26.4,43.9-51.7,50.6c-45.7,12-228.7,12-228.7,12s-183.1,0-228.7-12c-25.3-6.7-45-26-51.7-50.6 c-16.3-91.2-16.3-184.4,0-275.5C233,358,252.7,338.7,278,332c45.7-12,228.7-12,228.7-12s183.1,0,228.7,11.9 c25.3,6.7,45,26,51.7,50.6l0,0C803.5,473.8,803.5,567,787.2,658.1z"
      />
      <path
        fill="#fff"
        d="M787.2,382.6c-6.7-24.6-26.4-43.9-51.7-50.6C689.8,320,506.7,320,506.7,320s-183.1,0-228.7,12 c-25.3,6.7-45,26-51.7,50.6c-16.3,91.2-16.3,184.4,0,275.5c6.7,24.6,26.4,43.9,51.7,50.6c45.7,12,228.7,12,228.7,12 s183.1,0,228.7-12c25.3-6.7,45-26,51.7-50.6C803.5,567,803.5,473.8,787.2,382.6L787.2,382.6z M446.8,604.9V435.8l153,84.6 L446.8,604.9z"
      />
      <polygon fill="#ff0000" points="446.8,604.9 599.8,520.3 446.8,435.8" />
    </symbol>

    <symbol id="social-btn-ig" viewBox="0 0 132.004 132">
      <defs>
        <linearGradient id="social-btn-ig-lga">
          <stop offset="0" stop-color="#fd5" />
          <stop offset=".1" stop-color="#fd5" />
          <stop offset=".5" stop-color="#ff543e" />
          <stop offset="1" stop-color="#c837ab" />
        </linearGradient>
        <linearGradient id="social-btn-ig-lgb">
          <stop offset="0" stop-color="#3771c8" />
          <stop stop-color="#3771c8" offset=".128" />
          <stop offset="1" stop-color="#60f" stop-opacity="0" />
        </linearGradient>
        <radialGradient
          id="social-btn-ig-rgc"
          cx="158.429"
          cy="578.088"
          r="65"
          xlink:href="#social-btn-ig-lga"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 -1.98198 1.8439 0 -1031.402 454.004)"
          fx="158.429"
          fy="578.088"
        />
        <radialGradient
          id="social-btn-ig-rgd"
          cx="147.694"
          cy="473.455"
          r="65"
          xlink:href="#social-btn-ig-lgb"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(.17394 .86872 -3.5818 .71718 1648.348 -458.493)"
          fx="147.694"
          fy="473.455"
        />
      </defs>
      <path
        fill="url(#social-btn-ig-rgc)"
        d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
        transform="translate(1.004 1)"
      />
      <path
        fill="url(#social-btn-ig-rgd)"
        d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
        transform="translate(1.004 1)"
      />
      <path
        fill="#fff"
        d="M66.004 18c-13.036 0-14.672.057-19.792.29-5.11.234-8.598 1.043-11.65 2.23-3.157 1.226-5.835 2.866-8.503 5.535-2.67 2.668-4.31 5.346-5.54 8.502-1.19 3.053-2 6.542-2.23 11.65C18.06 51.327 18 52.964 18 66s.058 14.667.29 19.787c.235 5.11 1.044 8.598 2.23 11.65 1.227 3.157 2.867 5.835 5.536 8.503 2.667 2.67 5.345 4.314 8.5 5.54 3.054 1.187 6.543 1.996 11.652 2.23 5.12.233 6.755.29 19.79.29 13.037 0 14.668-.057 19.788-.29 5.11-.234 8.602-1.043 11.656-2.23 3.156-1.226 5.83-2.87 8.497-5.54 2.67-2.668 4.31-5.346 5.54-8.502 1.18-3.053 1.99-6.542 2.23-11.65.23-5.12.29-6.752.29-19.788 0-13.036-.06-14.672-.29-19.792-.24-5.11-1.05-8.598-2.23-11.65-1.23-3.157-2.87-5.835-5.54-8.503-2.67-2.67-5.34-4.31-8.5-5.535-3.06-1.187-6.55-1.996-11.66-2.23-5.12-.233-6.75-.29-19.79-.29zm-4.306 8.65c1.278-.002 2.704 0 4.306 0 12.816 0 14.335.046 19.396.276 4.68.214 7.22.996 8.912 1.653 2.24.87 3.837 1.91 5.516 3.59 1.68 1.68 2.72 3.28 3.592 5.52.657 1.69 1.44 4.23 1.653 8.91.23 5.06.28 6.58.28 19.39s-.05 14.33-.28 19.39c-.214 4.68-.996 7.22-1.653 8.91-.87 2.24-1.912 3.835-3.592 5.514-1.68 1.68-3.275 2.72-5.516 3.59-1.69.66-4.232 1.44-8.912 1.654-5.06.23-6.58.28-19.396.28-12.817 0-14.336-.05-19.396-.28-4.68-.216-7.22-.998-8.913-1.655-2.24-.87-3.84-1.91-5.52-3.59-1.68-1.68-2.72-3.276-3.592-5.517-.657-1.69-1.44-4.23-1.653-8.91-.23-5.06-.276-6.58-.276-19.398s.046-14.33.276-19.39c.214-4.68.996-7.22 1.653-8.912.87-2.24 1.912-3.84 3.592-5.52 1.68-1.68 3.28-2.72 5.52-3.592 1.692-.66 4.233-1.44 8.913-1.655 4.428-.2 6.144-.26 15.09-.27zm29.928 7.97c-3.18 0-5.76 2.577-5.76 5.758 0 3.18 2.58 5.76 5.76 5.76 3.18 0 5.76-2.58 5.76-5.76 0-3.18-2.58-5.76-5.76-5.76zm-25.622 6.73c-13.613 0-24.65 11.037-24.65 24.65 0 13.613 11.037 24.645 24.65 24.645C79.617 90.645 90.65 79.613 90.65 66S79.616 41.35 66.003 41.35zm0 8.65c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16-8.837 0-16-7.164-16-16 0-8.837 7.163-16 16-16z"
      />
    </symbol>

    <!-- iOS Icons -->

    <symbol id="ios-add-to-home-screen" viewBox="0 0 128 128">
      <path
        fill="currentColor"
        d="M120.1,8c-6.6-6.5-15.8-8-28.6-8H36C23.7,0,14.5,1.4,7.9,8C1.4,14.6,0,23.7,0,36.2v55.2 c0,12.9,1.3,22,7.9,28.5c6.7,6.6,15.8,8,28.6,8h55c12.8,0,22.1-1.4,28.6-8c6.6-6.5,7.9-15.7,7.9-28.5V36.6 C128,23.6,126.7,14.5,120.1,8z M119.2,34.7v58.6c0,8.5-1.3,15.9-5.6,20.2c-4.3,4.4-11.8,5.7-20.2,5.7H34.6c-8.3,0-15.8-1.4-20.2-5.7 c-4.3-4.3-5.5-11.7-5.5-20.2V35.1c0-8.8,1.2-16.3,5.5-20.6c4.3-4.4,12-5.7,20.7-5.7h58.3c8.4,0,15.9,1.4,20.2,5.7 C117.9,18.9,119.2,26.3,119.2,34.7L119.2,34.7z M63.9,95.8c2.9,0,4.6-2,4.6-5.1V68.5h23.1c3,0,5-1.7,5-4.5c0-2.9-2-4.7-5-4.7H68.5 V36.2c0-3.1-1.7-5.1-4.6-5.1c-2.8,0-4.5,2.1-4.5,5.1v23.1H36.5c-3.1,0-5.2,1.8-5.2,4.7c0,2.8,2.2,4.5,5.2,4.5h22.9v22.3 C59.4,93.8,61.1,95.8,63.9,95.8z"
      />
    </symbol>

    <symbol id="ios-close" viewBox="0 0 128 128">
      <path
        fill="currentColor"
        d="M3.4,107.1c-4.6,4.5-4.6,12.6,0.1,17.4c4.9,4.6,12.5,4.7,17.4,0.1L64,81.5l43.1,43c4.9,4.8,12.7,4.7,17.5-0.2 c4.7-4.8,4.7-12.5,0-17.3l-43-43l43-43.1c4.7-4.9,4.6-12.6-0.1-17.4c-4.8-4.8-12.5-4.8-17.4-0.1L64,46.5L20.9,3.4 C16-1.2,8.4-1.1,3.5,3.5C-1.1,8.4-1.2,16,3.4,21L46.5,64L3.4,107.1z"
      />
    </symbol>

    <symbol id="ios-share" viewBox="0 0 100 128">
      <path
        fill="#007aff"
        d="M50,83.7c1.9,0,3.5-1.5,3.5-3.5v-62l-0.2-8.3l5.1,5.3l9.8,10.2c0.6,0.7,1.5,1,2.4,1 c1.7,0.1,3.2-1.3,3.2-3c0,0,0-0.1,0-0.1c0-0.9-0.4-1.7-1-2.4L52.6,1.2C51.9,0.4,51,0,50,0c-1,0-1.9,0.4-2.5,1.2L27.3,20.9 c-0.7,0.6-1.1,1.5-1.1,2.4c0,1.7,1.4,3.1,3.1,3.1c0.1,0,0.1,0,0.2,0c0.9,0,1.8-0.4,2.5-1l9.8-10.2l5.1-5.3l-0.2,8.3v62 C46.6,82.1,48.1,83.7,50,83.7z M16.9,128h66.1c11.2,0,16.9-5.8,16.9-16.8V52.4c0-11.1-5.7-16.9-16.9-16.9H66.7v7.1h16.2 c6.4,0,10,3.5,10,10.1v58.1c0,6.7-3.6,10.1-10,10.1H17.1c-6.4,0-9.9-3.4-9.9-10.1V52.7c0-6.6,3.5-10.1,9.9-10.1h16.3v-7.1H16.9 C5.7,35.5,0,41.2,0,52.4v58.8C0,122.3,5.7,128,16.9,128z"
      />
    </symbol>

    <symbol id="ios-share-medium" viewBox="0 0 100 128">
      <path
        fill="#007aff"
        d="M50.1,83.4c2.8,0,5.1-2.3,5.1-5.1V22l-0.4-8.3l3.4,4.1l7.7,8.3c0.9,1,2.1,1.5,3.4,1.5 c2.5,0.1,4.6-1.9,4.6-4.3c0-1.3-0.5-2.6-1.5-3.5L54,1.8C53,0.7,51.6,0,50.1,0c-1.5,0-2.9,0.7-3.9,1.8l-18.6,18 c-0.9,0.9-1.5,2.1-1.5,3.4c0,2.4,1.9,4.4,4.4,4.5c0.1,0,0.1,0,0.2,0c1.3,0,2.6-0.5,3.5-1.5l7.7-8.3l3.4-4.1L44.9,22v56.2 C44.9,81.1,47.2,83.4,50.1,83.4z M18.2,128h63.6c12,0,18.2-6.3,18.2-18.2V54.9c0-12-6.2-18.2-18.2-18.2H66.9v11.1h14.2 c5,0,7.9,2.7,7.9,8v53c0,5.4-2.9,8-7.9,8H18.9c-5.1,0-7.9-2.7-7.9-8v-53c0-5.4,2.8-8,7.9-8h14.4V36.7H18.2C6.2,36.7,0,42.9,0,54.9 v54.9C0,121.7,6.2,128,18.2,128z"
      />
    </symbol>

    <symbol id="calendar" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M21.6,2.7h-2.5V1.6c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v1.1h-5.5V1.6c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v1.1H6.1 V1.6c0-0.3-0.2-0.5-0.5-0.5S5.1,1.3,5.1,1.6v1.1H2.4c-1.1,0-1.9,0.9-1.9,1.9V21c0,1.1,0.9,1.9,1.9,1.9h19.2c1.1,0,1.9-0.9,1.9-1.9 V4.7C23.5,3.6,22.6,2.7,21.6,2.7z M22.5,21c0,0.5-0.4,0.9-0.9,0.9H2.4c-0.5,0-0.9-0.4-0.9-0.9V4.7c0-0.5,0.4-0.9,0.9-0.9h2.7v0.6 c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V3.7h5.5v0.6c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V3.7h5.5v0.6c0,0.3,0.2,0.5,0.5,0.5 s0.5-0.2,0.5-0.5V3.7h2.5c0.5,0,0.9,0.4,0.9,0.9V21z"
      />
      <path
        fill="currentColor"
        d="M21.3,7.6H2.8c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h18.5c0.3,0,0.5-0.2,0.5-0.5S21.6,7.6,21.3,7.6z"
      />
      <path
        fill="currentColor"
        d="M11.4,13c0-1.2-0.9-1.9-2.2-1.9c-0.8,0-1.6,0.3-2.1,0.6l0.3,0.8c0.4-0.2,1-0.5,1.6-0.5c0.8,0,1.3,0.4,1.3,1.2 c0,1.8-2.1,3.4-3.2,4.6v0.8h4.4v-0.9H8.3C9.6,16.5,11.4,14.9,11.4,13z"
      />
      <polygon
        fill="currentColor"
        points="16.6,14.9 15.6,14.9 15.6,16.6 13.4,16.6 15.7,11.5 14.8,11.1 12.3,16.8 12.3,17.4 15.6,17.4 15.6,18.6 16.6,18.6  16.6,17.4 17.6,17.4 17.6,16.6 16.6,16.6"
      />
    </symbol>

    <symbol id="info-i-circle" viewBox="0 0 24 24">
      <circle fill="#35bbe0" cx="12" cy="12" r="12" />
      <path
        fill="currentColor"
        d="M11.3,15.5c0.1,0.1,0.2,0.2,0.4,0.2c0.6,0,1.1-0.3,1.4-0.8c0.3-0.4,0.6-0.8,0.8-1.3l1.2-2.8 c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1l-0.6,1.4l-0.9,2.1c-0.2,0.5-0.5,1-0.8,1.5C13,16.3,12,16.9,10.9,17 c-0.5,0-0.9-0.2-1.2-0.7c-0.2-0.4-0.4-0.8-0.3-1.3c0-0.5,0.1-1.1,0.2-1.6c0.1-0.4,0.2-1,0.4-1.6c0.3-0.8,0.4-1.2,0.5-1.4 c0.1-0.3,0.2-0.7,0.2-1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0.1,0c0.3,0,0.4,0,0.5,0c0.2,0,0.4,0,0.7,0.1 c0.5,0.1,0.8,0.3,0.8,0.5c0,0.4-0.1,0.7-0.2,1c-0.1,0.2-0.2,0.7-0.5,1.5c-0.4,0.9-0.7,1.9-0.8,2.9C11.2,15.2,11.2,15.4,11.3,15.5z M13.6,7.4c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.3-0.4,0.5-0.6,0.7c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1 c-0.3-0.1-0.5-0.4-0.5-0.6c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0-0.4,0.1-0.6c0.1-0.3,0.4-0.5,0.6-0.6c0.1-0.1,0.3-0.1,0.4-0.1 c0.1,0,0.3,0,0.4,0.1c0.3,0.1,0.5,0.4,0.5,0.6C13.6,7.1,13.6,7.3,13.6,7.4z"
      />
    </symbol>

    <!-- HOFER ICONS -->

    <symbol viewBox="0 0 100 100" id="logo-c-facebook-color" xmlns="http://www.w3.org/2000/svg">
      <g fill-rule="nonzero" fill="none">
        <path
          d="M100 50c0-27.614-22.386-50-50-50S0 22.386 0 50c0 24.956 18.284 45.642 42.188 49.393v-34.94H29.492V50h12.695V38.984c0-12.53 7.465-19.453 18.886-19.453 5.469 0 11.193.977 11.193.977v12.305H65.96c-6.211 0-8.148 3.854-8.148 7.812V50H71.68l-2.217 14.453h-11.65v34.94C81.715 95.642 100 74.956 100 50z"
          fill="#1877F2"
        />
        <path
          d="M69.463 64.453L71.68 50H57.812v-9.375c0-3.954 1.938-7.813 8.149-7.813h6.305V20.508s-5.722-.977-11.193-.977c-11.42 0-18.886 6.922-18.886 19.453V50H29.492v14.453h12.695v34.94c5.177.81 10.449.81 15.626 0v-34.94h11.65z"
          fill="#FFF"
        />
      </g>
    </symbol>

    <symbol viewBox="0 0 100 100" id="logo-c-youtube-color" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <circle fill="red" cx="50" cy="50" r="50" />
        <g fill-rule="nonzero">
          <path
            d="M78.745 35.6a7.54 7.54 0 00-5.304-5.339C68.75 29 50 29 50 29s-18.75 0-23.44 1.261a7.54 7.54 0 00-5.305 5.339C20 40.311 20 50.136 20 50.136s0 9.825 1.255 14.537a7.54 7.54 0 005.304 5.338C31.25 71.273 50 71.273 50 71.273s18.75 0 23.44-1.262a7.54 7.54 0 005.305-5.338C80 59.96 80 50.136 80 50.136s0-9.825-1.255-14.536z"
            fill="#FFF"
          />
          <path fill="red" d="M43.864 59.058l15.681-8.922-15.681-8.921z" />
        </g>
      </g>
    </symbol>

    <symbol viewBox="0 0 1038.66 1038.66" id="logo-c-instagram-color" xmlns="http://www.w3.org/2000/svg">
      <circle fill="#c13584" cx="519.33" cy="519.33" r="519.33" />
      <path
        fill="#fff"
        d="M519.33 312.8c67.27 0 75.23.26 101.8 1.47 24.56 1.12 37.9 5.23 46.78 8.68a83.43 83.43 0 0147.81 47.81c3.45 8.87 7.55 22.21 8.67 46.77 1.21 26.57 1.47 34.54 1.47 101.8s-.26 75.23-1.47 101.8c-1.12 24.56-5.22 37.9-8.67 46.78a83.51 83.51 0 01-47.81 47.81c-8.88 3.45-22.22 7.55-46.78 8.67-26.56 1.21-34.53 1.47-101.8 1.47s-75.24-.26-101.8-1.47c-24.56-1.12-37.9-5.22-46.77-8.67a83.43 83.43 0 01-47.81-47.81c-3.45-8.88-7.56-22.22-8.68-46.78-1.21-26.57-1.47-34.53-1.47-101.8s.26-75.23 1.47-101.8c1.12-24.56 5.23-37.9 8.68-46.77a83.36 83.36 0 0147.81-47.81c8.87-3.45 22.21-7.56 46.77-8.68 26.57-1.21 34.54-1.47 101.8-1.47m0-45.39c-68.42 0-77 .29-103.86 1.52s-45.13 5.48-61.16 11.71a128.74 128.74 0 00-73.67 73.67c-6.23 16-10.49 34.34-11.71 61.16s-1.52 35.44-1.52 103.86.29 77 1.52 103.87 5.48 45.12 11.71 61.15a128.8 128.8 0 0073.67 73.68c16 6.22 34.34 10.48 61.16 11.71s35.44 1.52 103.86 1.52 77-.29 103.87-1.51 45.12-5.49 61.15-11.71a128.85 128.85 0 0073.68-73.68c6.22-16 10.48-34.34 11.71-61.15s1.52-35.46 1.52-103.88-.29-77-1.51-103.86-5.49-45.13-11.71-61.16a128.8 128.8 0 00-73.68-73.67c-16-6.23-34.34-10.49-61.15-11.71s-35.45-1.52-103.87-1.52z"
      />
      <path
        fill="#fff"
        d="M519.33 389.97A129.37 129.37 0 10648.7 519.33a129.36 129.36 0 00-129.37-129.36zm0 213.34a84 84 0 1184-84 84 84 0 01-84 84z"
      />
      <circle fill="#fff" cx="653.81" cy="384.85" r="30.23" />
    </symbol>

    <symbol viewBox="0 0 400 400" id="logo-round-tiktok" xmlns="http://www.w3.org/2000/svg">
      <g fill-rule="evenodd">
        <path
          d="M212.951 75.417c-.027.045-.145 41.483-.261 92.083l-.212 92-.889 3.259c-6.388 23.431-32.011 34.842-53.422 23.792-4.537-2.342-7.422-4.74-12.517-10.403-.65-.723-2.293-1.99-3.65-2.815-10.716-6.515-17.492-19.728-16.906-32.967 1.022-23.1 23.327-40.11 45.51-34.708 1.525.372 2.837.675 2.917.675.08 0 .146-7.972.146-17.715v-17.716l-3.584.23c-60.584 3.885-96.453 70.321-66.451 123.081 6.53 11.482 11.051 16.068 20.868 21.163 44.89 23.301 100.385.705 116.156-47.295 4.252-12.941 4.115-10.761 4.254-67.643l.119-48.729 3.98 2.604c15.439 10.096 34.3 16.089 53.244 16.917l4.753.207-.086-17.594-.087-17.594-3.368-.942c-10.711-2.996-21.081-9.275-28.676-17.363-.941-1.002-2.965-2.712-4.497-3.799-10.927-7.759-19.958-21.152-23.417-34.728l-1.04-4.084h-16.418c-9.029 0-16.439.038-16.466.084"
          fill="#fcfcfc"
        />
        <path
          d="M199.667 154.79c0 99.761.132 93.249-2.023 99.417-7.157 20.486-30.104 30.801-49.687 22.334-3.069-1.327-3.052-1.138.213 2.388 19.907 21.502 55.632 12.393 63.419-16.17l.889-3.259.212-92c.116-50.6.234-92.038.261-92.083.027-.046 7.445-.084 16.485-.084h16.435l-.414-2.916c-.227-1.604-.423-4.155-.435-5.667L245 64h-45.333v90.79m75.166-36.798c7.875 8.274 18.241 14.495 29.084 17.453l3.083.842v-9.829l-4.583-.399c-10-.87-19.494-3.919-27.75-8.911l-1.5-.907 1.666 1.751m-122.5 42.15c-72.139 9.196-98.278 100.979-41.666 146.302 4.043 3.237 4.358 3.291 1.779.305-43.865-50.791-9.648-131.302 57.637-135.617l3.584-.23v-10.493l-1.084-.212c-2.373-.464-16.733-.503-20.25-.055"
          fill="#24f4ec"
        />
        <path
          d="M246.002 75.75c.001.229.443 2.092.981 4.14 3.686 14.014 12.43 26.575 24.35 34.978l1.834 1.292-1.358-1.663c-8.047-9.856-13.071-22.772-13.71-35.247l-.201-3.917h-5.949c-4.538 0-5.948.099-5.947.417M307 153.885v17.552l-4.75-.207c-18.941-.828-37.803-6.821-53.241-16.917l-3.98-2.604-.119 48.729c-.139 56.882-.002 54.702-4.254 67.643-16.768 51.035-78.077 72.595-123.166 43.315l-2.657-1.726 2.991 2.888c42.674 41.207 113.082 25.761 134.668-29.543 5.342-13.685 5.508-15.909 5.508-73.718v-46.273l4.083 2.678c15.143 9.933 34.489 16.088 53 16.863l4.917.205v-45.008l-3.325-.229c-1.828-.126-4.641-.445-6.25-.709-1.609-.263-3.038-.482-3.175-.485-.138-.003-.25 7.893-.25 17.546m-133.333 34.731c0 9.745-.066 17.717-.146 17.717-.08 0-1.392-.303-2.917-.675-14.338-3.492-30.358 2.734-38.878 15.11-11.943 17.349-7.389 41.053 10.084 52.492 3.191 2.088 3.221 2.074 1.534-.702-17.472-28.76 9.29-63.503 42.406-55.053l.917.234v-45.985l-1.417-.216c-.779-.119-3.704-.311-6.5-.427l-5.083-.211v17.716"
          fill="#fc2c54"
        />
        <path
          d="M186.667.216C92.478 6.902 15.55 77.713 2.036 170.167c-3.296 22.552-2.626 48.093 1.837 70 15.971 78.397 77.563 139.989 155.96 155.96 25.638 5.223 54.696 5.223 80.334 0 78.388-15.97 139.99-77.572 155.96-155.96 5.223-25.638 5.223-54.696 0-80.334C378.653 74.061 308.728 10.806 220.333.809c-4.622-.523-28.683-.947-33.666-.593m58.358 66.534c.014 1.512.201 4.025.417 5.583l.391 2.834 6.033.09 6.033.091.2 3.909c.664 12.949 6.956 28.445 14.724 36.264 4.683 4.714 18.273 9.553 29.594 10.538l4.583.399v9.804l2.083.378c1.146.208 4.071.557 6.5.774l4.417.396v44.96l-4.917-.205c-18.511-.775-37.857-6.93-53-16.863L258 163.024v46.273c0 57.809-.166 60.033-5.508 73.718-21.739 55.697-91.128 70.65-135.173 29.131-1.933-1.822-4.778-4.288-6.322-5.479-44.54-34.368-40.348-104.87 8.016-134.826 15.079-9.34 36.36-14.075 52.737-11.734l1.917.274V170.9l5.083.211c2.796.116 5.721.308 6.5.427l1.417.216v45.985l-.917-.234c-33.144-8.457-59.571 25.749-42.452 54.947 1.689 2.882 2.321 3.337 6.931 4.992 19.055 6.84 40.596-3.717 47.415-23.237 2.155-6.168 2.023.344 2.023-99.417V64H245l.025 2.75"
          fill="#040404"
        />
      </g>
    </symbol>

    <symbol viewBox="0 0 243 243" id="logo-c-pinterest-color" xmlns="http://www.w3.org/2000/svg">
      <circle fill="#FFF" cx="121.5" cy="121.5" r="121.5" />
      <path
        fill="#E60019"
        d="M121.5 0C54.4 0 0 54.4 0 121.5 0 173 32 217 77.2 234.7c-1.1-9.6-2-24.4.4-34.9 2.2-9.5 14.2-60.4 14.2-60.4s-3.6-7.3-3.6-18c0-16.9 9.8-29.5 22-29.5 10.4 0 15.4 7.8 15.4 17.1 0 10.4-6.6 26-10.1 40.5-2.9 12.1 6.1 22 18 22 21.6 0 38.2-22.8 38.2-55.6 0-29.1-20.9-49.4-50.8-49.4-34.6 0-54.9 25.9-54.9 52.7 0 10.4 4 21.6 9 27.7 1 1.2 1.1 2.3.8 3.5-.9 3.8-3 12.1-3.4 13.8-.5 2.2-1.8 2.7-4.1 1.6-15.2-7.1-24.7-29.2-24.7-47.1 0-38.3 27.8-73.5 80.3-73.5 42.1 0 74.9 30 74.9 70.2 0 41.9-26.4 75.6-63 75.6-12.3 0-23.9-6.4-27.8-14 0 0-6.1 23.2-7.6 28.9-2.7 10.6-10.1 23.8-15.1 31.9 11.4 3.5 23.4 5.4 36 5.4 67.1 0 121.5-54.4 121.5-121.5C243 54.4 188.6 0 121.5 0z"
      />
    </symbol>

    <symbol viewBox="0 0 500 410" id="logo-c-linkedin-color" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#231f20"
        d="M455.29 328.44c4.78-.59 7.28-3.23 7.28-7.64 0-5.39-3.27-8-10-8H441.8v28.34h4v-12.35h5l.1.15 7.8 12.19h4.39l-8.34-12.62zm-4.71-2.85h-4.72V316h6c3.12 0 6.64.51 6.64 4.56-.02 4.65-3.58 5.03-7.92 5.03z"
      />
      <path
        fill="#231f20"
        d="M451.09 300.19A26.54 26.54 0 00424.21 327a26.53 26.53 0 0026.88 26.81A26.53 26.53 0 00477.87 327a26.54 26.54 0 00-26.78-26.81zm0 50.11c-12.83 0-22.9-10.14-22.9-23.3s10.07-23.3 22.9-23.3 22.88 10.15 22.88 23.3-10.08 23.3-22.88 23.3z"
      />
      <path
        fill="#007bb6"
        d="M376.88 0H30.02c-16.57 0-30 13.14-30 29.35v348.28c0 16.21 13.45 29.37 30 29.37h346.86c16.59 0 30.1-13.16 30.1-29.37V29.35c0-16.21-13.51-29.35-30.1-29.35z"
      />
      <path
        fill="#fff"
        d="M60.3 152.58h60.42v194.23H60.3zM90.53 56a35 35 0 0135 35 35 35 0 01-35 35 35 35 0 01-35-35 35 35 0 0135-35M158.59 152.58h57.87v26.55h.83c8-15.27 27.74-31.37 57.11-31.37 61.12 0 72.41 40.22 72.41 92.53v106.52h-60.36v-94.46c0-22.52-.38-51.49-31.36-51.49-31.41 0-36.2 24.54-36.2 49.88v96.07h-60.3z"
      />
    </symbol>
  </svg> `,
})
export class SvgSymbolDefinitionsComponent {}
