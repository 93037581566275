export interface ISnowCanvasConfig {
  canvas?: HTMLCanvasElement;
  canvasClass?: string;
  container?: HTMLElement;
  containerBehind?: HTMLElement;
  flakeCount: number;
  minMouseDistance: number;
  size: { min: number; max: number };
  opacity: { min: number; max: number };
  speed: number;
  fadeOut: false | number;
  startFromTop: boolean;
  fpsLimit: number;
  resetOnNewContainerDimensions: boolean;
  onFrame: () => void;
}

export interface ISnowCanvasFlake {
  size: number;
  speed: number;
  x: number;
  y: number;
  velX: number;
  velY: number;
  stepSize: number;
  step: number;
  opacity: number;
  element: HTMLElement;
}

const FPS_REFERENCE = 60;

export class SnowCanvas {
  private readonly _config: ISnowCanvasConfig = {
    flakeCount: 100,
    minMouseDistance: 0,
    size: { min: 2, max: 16 },
    opacity: { min: 0.5, max: 0.9 },
    speed: 0.25,
    fadeOut: 0.8,
    startFromTop: false,
    fpsLimit: FPS_REFERENCE,
    resetOnNewContainerDimensions: false,
    onFrame: null,
  };

  private readonly _flakes: ISnowCanvasFlake[] = [];

  private readonly _container: HTMLElement;

  private readonly _containerBehind: HTMLElement;

  // private readonly _context: CanvasRenderingContext2D;

  private _containerWidth: number;

  private _containerHeight: number;

  public _snowing: boolean;

  private _refreshContainerDimensionsRequested: boolean;

  private _mX = -Infinity;

  private _mY = -Infinity;

  public constructor(config?: Partial<ISnowCanvasConfig>) {
    Object.assign(this._config, config ?? {});

    this._container = this._config.container;
    this._containerBehind = this._config.containerBehind;

    this._containerWidth = this._container.offsetWidth;
    this._containerHeight = this._container.offsetHeight;

    if (this._config.minMouseDistance > 0) {
      // TODO [SR]: requires pointer-events (!)
      this._container.addEventListener("mousemove", (e) => {
        this._mX = e.offsetX;
        this._mY = e.offsetY;
      });
    }

    for (let i = 0; i < this._config.flakeCount; i++) {
      this._flakes.push(this._reset({} as ISnowCanvasFlake, true));
    }

    window.addEventListener("resize", () => (this._refreshContainerDimensionsRequested = true));
  }

  public start() {
    this._snowing = true;
    requestAnimationFrame((ts) => this._nextFrame(ts));
  }

  public stop() {
    this._snowing = false;
  }

  public refreshContainerDimensions() {
    this._containerWidth = this._container.offsetWidth;
    this._containerHeight = this._container.offsetHeight;
    if (this._config.resetOnNewContainerDimensions) {
      this.resetFlakes();
    }
  }

  public resetFlakes() {
    for (const flake of this._flakes) {
      this._reset(flake, true);
    }
  }

  private _reset(flake: ISnowCanvasFlake, random = false) {
    flake.size = Math.random() * this._config.size.max + this._config.size.min;
    flake.speed = Math.sqrt(flake.size) * this._config.speed;
    flake.x = Math.floor(Math.random() * this._containerWidth);
    flake.y = random
      ? Math.floor(Math.random() * this._containerHeight) * (this._config.startFromTop ? -1 : 1)
      : -flake.size;
    flake.velX = 0;
    flake.velY = flake.speed;
    flake.stepSize = Math.random() / 30;
    flake.step = 0;
    flake.opacity = Math.random() * this._config.opacity.max + this._config.opacity.min;

    if (!flake.element) {
      flake.element = document.createElement("div");
      flake.element.classList.add("snowflake");
      let container = this._container;
      if (this._containerBehind && Math.random() < 0.5) {
        container = this._containerBehind;
      }
      container.appendChild(flake.element);
    }

    return flake;
  }

  private _lastFrameTime = -Infinity;

  private _nextFrame(timestamp: DOMHighResTimeStamp) {
    if (timestamp < this._lastFrameTime + 1000 / this._config.fpsLimit) {
      requestAnimationFrame((ts) => this._nextFrame(ts));
      return;
    }

    if (this._refreshContainerDimensionsRequested) {
      this._refreshContainerDimensionsRequested = false;
      this.refreshContainerDimensions();
    }

    const timeDelta = timestamp - this._lastFrameTime;
    const delta = {
      time: timeDelta,
      frames: (this._config.fpsLimit * timeDelta) / 1000,
    };
    this._lastFrameTime = timestamp;

    this._drawSnow(delta);

    if (!this._snowing) {
      return;
    }
    requestAnimationFrame((ts) => this._nextFrame(ts));
  }

  private _drawSnow({ time, frames }: { time: number; frames: number }) {
    const fadeHeight = this._containerHeight * (this._config.fadeOut || 1);
    const fadeDistance = this._containerHeight - fadeHeight;
    for (const flake of this._flakes) {
      if (this._config.minMouseDistance) {
        const dist = Math.sqrt((flake.x - this._mX) ** 2 + (flake.y - this._mY) ** 2);
        if (dist < this._config.minMouseDistance) {
          const deltaV = this._config.minMouseDistance / (2 * dist);
          flake.velX -= deltaV * ((this._mX - flake.x) / dist);
          flake.velY -= deltaV * ((this._mY - flake.y) / dist);
        }
      }

      flake.step += 0.05;
      flake.velX *= 0.95;
      flake.velX += Math.cos(flake.step) * flake.stepSize;
      flake.velY = Math.max(flake.velY, flake.speed * (FPS_REFERENCE / this._config.fpsLimit));

      flake.x += flake.velX;
      flake.y += flake.velY;

      if (flake.x <= 0 || flake.x >= this._containerWidth || flake.y <= -this._containerHeight) {
        this._reset(flake, true);
      } else if (flake.y >= this._containerHeight) {
        this._reset(flake);
      } else if (fadeDistance && flake.y > fadeHeight) {
        const o = Math.max(0, 1 - (flake.y + flake.size - fadeHeight) / fadeDistance);
        flake.opacity = Math.min(o, flake.opacity);
      }

      flake.element.style.setProperty("--size", flake.size + "px");
      flake.element.style.setProperty("--x", flake.x + "px");
      flake.element.style.setProperty("--y", flake.y + "px");
      flake.element.style.setProperty("--opacity", String(flake.opacity));
    }

    if (this._config.onFrame instanceof Function) {
      this._config.onFrame();
    }
  }

  public toggleClassOnContainersParent(token: string, force: boolean) {
    this._container.parentElement.classList.toggle(token, force);
    if (this._containerBehind) {
      this._containerBehind.parentElement.classList.toggle(token, force);
    }
  }
}
