import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons/faArrowRight";
import { faCamera } from "@fortawesome/pro-light-svg-icons/faCamera";
import { faCompass } from "@fortawesome/pro-light-svg-icons/faCompass";
import { faExclamationCircle as faExclamationCircleLight } from "@fortawesome/pro-light-svg-icons/faExclamationCircle";
import { faHandPointer } from "@fortawesome/pro-light-svg-icons/faHandPointer";
import { faLocation } from "@fortawesome/pro-light-svg-icons/faLocation";
import { faMobile } from "@fortawesome/pro-light-svg-icons/faMobile";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faBackspace } from "@fortawesome/pro-light-svg-icons/faBackspace";
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons/faTimesCircle";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faAngleUp } from "@fortawesome/pro-solid-svg-icons/faAngleUp";
import { faBan } from "@fortawesome/pro-solid-svg-icons/faBan";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons/faCircleNotch";
import { faExclamation } from "@fortawesome/pro-solid-svg-icons/faExclamation";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { faInfo } from "@fortawesome/pro-solid-svg-icons/faInfo";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";

const svgPathData = {
  hoferLogo: [
    "M9.32097,3.02101c-1.046,0 -1.466,0.719 -1.858,1.872l-3.565,10.5c-0.306,0.9 -0.7,1.449 -1.342,1.525l0,0.059l1.849,0c1.046,0 1.466,-0.719 1.858,-1.872l3.565,-10.5c0.306,-0.9 0.73,-1.445 1.38,-1.525l0,-0.059l-1.887,0z",
    "M12.67297,3.00001c-1.046,0 -1.466,0.719 -1.858,1.872l-3.566,10.486c-0.306,0.9 -0.7,1.449 -1.342,1.525l0,0.059l1.852,0c1.046,0 1.466,-0.719 1.858,-1.872l3.562,-10.486c0.306,-0.9 0.73,-1.445 1.38,-1.525l0,-0.059l-1.886,0z",
    "M5.96901,3.04099c-1.046,0 -1.466,0.719 -1.858,1.872l-4.113,12.087l1.046,0c1.046,0 1.466,-0.719 1.858,-1.872l3.569,-10.507c0.306,-0.9 0.73,-1.445 1.38,-1.525l0,-0.058l-1.882,0l0,0.003z",
    "M16.62801,7.908a1.386,1.386 0 0 0 -1.535,-0.891l-1.862,0l-0.733,2.161l3.648,0a1.4,1.4 0 0 1 1.246,0.461l0.066,0s-0.737,-1.542 -0.83,-1.731z",
    "M17.94901,11.02599a1.4,1.4 0 0 0 -1.538,-0.888l-4.244,0l-0.733,2.161l6,0a1.38,1.38 0 0 1 1.242,0.475l0.065,0s-0.699,-1.555 -0.792,-1.748z",
    "M19.30799,14.258a1.439,1.439 0 0 0 -1.587,-0.981l-6.621,0l-0.609,1.79q-0.067,0.2 -0.134,0.372l8.38,0a1.355,1.355 0 0 1 1.2,0.489l0.065,0s-0.652,-1.574 -0.694,-1.67z",
  ],
  packerl: [
    "M23.5,8.2c0-1.3-1.1-2.4-2.4-2.4h-5.6c0.9-0.4,1.7-0.8,1.8-1.8v0c0-1.3-1.1-2.2-2.3-2.2c-1.3,0-1.9,1.1-2.4,2.1c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.2-0.2-0.4-0.3-0.6C11.6,2.3,11,1,9.5,1c0,0,0,0,0,0C8,0.9,6.8,2,6.7,3.4c0,1.2,1,1.7,2.1,2.2l0.2,0.1H2.9c-1.3,0-2.4,1.1-2.4,2.4c0,1,0.6,1.8,1.4,2.2v8.1C1.9,21,4,23,6.4,23h11.1c2.5,0,4.5-2,4.5-4.5v-8.1C22.9,10,23.5,9.2,23.5,8.2z",
    "M10.6,9.6V6.8h2.3v2.8H10.6z",
    "M12.9,10.6V22h-2.3V10.6H12.9z",
    "M13.5,4.2c0.5-1.1,0.9-1.5,1.5-1.5c0,0,0,0,0.1,0c0.7,0,1.3,0.6,1.3,1.3c0,0.4-0.4,0.6-1.3,1c-0.2,0.1-0.3,0.2-0.5,0.2c-0.4,0.3-0.9,0.4-1.5,0.5C13,5.2,13.2,4.7,13.5,4.2z",
    "M7.7,3.5 C7.8,2.6,8.5,2,9.3,2c0,0,0.1,0,0.1,0c0.8,0,1.2,0.5,1.8,1.9c0.3,0.6,0.5,1.2,0.6,1.9C11.1,5.6,10.5,5.3,9.9,5L9.3,4.7C8.1,4.2,7.7,3.9,7.7,3.5z",
    "M1.5,8.2c0-0.8,0.6-1.4,1.4-1.4h6.7v2.8H2.9C2.1,9.6,1.5,9,1.5,8.2z",
    "M2.9,18.5v-7.9h6.7V22H6.4 C4.5,22,2.9,20.5,2.9,18.5z",
    "M21.1,18.5c0,1.9-1.6,3.5-3.5,3.5h-3.6V10.6h7.1V18.5z",
    "M21.1,9.6h-7.2V6.8h7.2c0.8,0,1.4,0.6,1.4,1.4 C22.5,9,21.9,9.6,21.1,9.6z",
  ],
  store: [
    "M23.5,9l-2.1-7c-0.2-0.6-0.7-1-1.4-1H4C3.4,1,2.8,1.4,2.6,2L0.5,9c0,0.2,0,0.3,0.1,0.4C0.7,9.6,0.8,9.6,1,9.6h0.9l0,12.1C2,22.4,2.6,23,3.3,23c0,0,0.1,0,0.1,0l17.2,0c0.8,0.1,1.4-0.6,1.5-1.3v-12H23c0.2,0,0.3-0.1,0.4-0.2C23.5,9.3,23.5,9.1,23.5,9z",
    "M15.8,2l1,6.7h-4.1V2H15.8z",
    "M3.6,2.3C3.6,2.1,3.8,2,4,2h7.6v6.7H7l0.7-4.2c0-0.3-0.1-0.5-0.4-0.6C7,3.8,6.8,4,6.7,4.3L6,8.6H1.7L3.6,2.3z",
    "M6,21.8v-5c0-0.8,0.7-1.5,1.5-1.5h1c0.8,0,1.5,0.7,1.5,1.5v5H6z",
    "M21.1,21.6c0,0.2-0.2,0.4-0.5,0.4L11,22v-5.2c0-1.4-1.1-2.5-2.5-2.5h-1c-1.4,0-2.5,1.1-2.5,2.5V22l-1.6,0c-0.2,0-0.4-0.2-0.4-0.4v-12h18.1L21.1,21.6z",
    "M17.8,8.6l-1-6.7H20c0.2,0,0.4,0.1,0.4,0.3l1.9,6.3H17.8z",
    // ---
    "M14.9,19.5h1.9c0.9,0,1.6-0.7,1.6-1.6v-2.8c0-0.9-0.7-1.6-1.6-1.6h-1.9c-0.9,0-1.6,0.7-1.6,1.6v2.8C13.3,18.8,14.1,19.5,14.9,19.5z",
    "M14.3,15.1c0-0.3,0.3-0.6,0.6-0.6h1.9c0.3,0,0.6,0.3,0.6,0.6v2.8c0,0.3-0.3,0.6-0.6,0.6h-1.9c-0.3,0-0.6-0.3-0.6-0.6V15.1z",
  ],
  receipt: [
    "M17.5 20h-15C1.1 20 0 18.9 0 17.5V.3C0 .2.1.1.2 0s.2 0 .3.1l1 1 1-1c.2-.1.4-.1.5 0l1 1 1-1c.2-.1.4-.1.6 0l1 1 1-1c.1-.1.3-.1.5 0l1 1 1-1c.1-.1.3-.1.4 0l1 1 1-1c.1-.1.3-.1.4 0l1 1 1-1c.3-.1.5-.1.6-.1.1 0 .2.2.2.3v14.4c0 .2-.1.3-.3.3-.2 0-.3-.1-.3-.3V1.1l-.7.7c-.1.1-.3.1-.4 0l-1-1-1 1c-.1.1-.3.1-.4 0l-1-1-1 1c-.4.1-.6.1-.7 0l-1-1-1 1c-.1.1-.3.1-.4 0l-1-1-1 1c-.3.1-.5.1-.6 0l-1-1-1 1c-.1.1-.3.1-.4 0l-.9-.7v16.4c0 1 .8 1.9 1.9 1.9s1.9-.8 1.9-1.9v-1.6c0-.2.1-.3.3-.3h15c.2 0 .3.1.3.3v1.6c0 1.4-1.1 2.5-2.5 2.5zm-13.4-.6h13.3c1 0 1.9-.8 1.9-1.9v-1.2H5v1.2c0 .7-.3 1.4-.9 1.9z",
    "M9.1 8.8H2.8c-.2 0-.3-.1-.3-.3s.1-.3.3-.3H9c.2 0 .3.1.3.3s-.1.3-.2.3zm3.7 0h-1.9c-.2 0-.3-.1-.3-.3s.1-.3.3-.3h1.9c.2 0 .3.1.3.3s-.1.3-.3.3zM9.1 6.9H2.8c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3H9c.2 0 .3.1.3.3.1.1-.1.3-.2.3zm3.7 0h-1.9c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h1.9c.2 0 .3.1.3.3 0 .1-.1.3-.3.3zm-3.7 3.7H2.8c-.2 0-.3-.1-.3-.3s.1-.3.3-.3H9c.2 0 .3.1.3.3s-.1.3-.2.3zm3.7 0h-1.9c-.2 0-.3-.1-.3-.3s.1-.3.3-.3h1.9c.2 0 .3.1.3.3s-.1.3-.3.3zm0 1.9h-1.9c-.2 0-.3-.1-.3-.3s.1-.3.3-.3h1.9c.2 0 .3.1.3.3s-.1.3-.3.3z",
  ],
  qrcode: [
    "M104 104C112.8 104 120 111.2 120 120V136C120 144.8 112.8 152 104 152H88C79.16 152 72 144.8 72 136V120C72 111.2 79.16 104 88 104H104zM144 32C170.5 32 192 53.49 192 80V176C192 202.5 170.5 224 144 224H48C21.49 224 0 202.5 0 176V80C0 53.49 21.49 32 48 32H144zM144 64H48C39.16 64 32 71.16 32 80V176C32 184.8 39.16 192 48 192H144C152.8 192 160 184.8 160 176V80C160 71.16 152.8 64 144 64zM72 376C72 367.2 79.16 360 88 360H104C112.8 360 120 367.2 120 376V392C120 400.8 112.8 408 104 408H88C79.16 408 72 400.8 72 392V376zM144 288C170.5 288 192 309.5 192 336V432C192 458.5 170.5 480 144 480H48C21.49 480 0 458.5 0 432V336C0 309.5 21.49 288 48 288H144zM144 320H48C39.16 320 32 327.2 32 336V432C32 440.8 39.16 448 48 448H144C152.8 448 160 440.8 160 432V336C160 327.2 152.8 320 144 320zM360 104C368.8 104 376 111.2 376 120V136C376 144.8 368.8 152 360 152H344C335.2 152 328 144.8 328 136V120C328 111.2 335.2 104 344 104H360zM256 80C256 53.49 277.5 32 304 32H400C426.5 32 448 53.49 448 80V176C448 202.5 426.5 224 400 224H304C277.5 224 256 202.5 256 176V80zM288 80V176C288 184.8 295.2 192 304 192H400C408.8 192 416 184.8 416 176V80C416 71.16 408.8 64 400 64H304C295.2 64 288 71.16 288 80zM256 304C256 295.2 263.2 288 272 288H336C344.8 288 352 295.2 352 304V372H416V304C416 295.2 423.2 288 432 288C440.8 288 448 295.2 448 304V388C448 396.8 440.8 404 432 404H336C327.2 404 320 396.8 320 388V320H288V472C288 480.8 280.8 488 272 488C263.2 488 256 480.8 256 472V304zM320 448C320 439.2 327.2 432 336 432H352C360.8 432 368 439.2 368 448V464C368 472.8 360.8 480 352 480H336C327.2 480 320 472.8 320 464V448zM432 432C440.8 432 448 439.2 448 448V464C448 472.8 440.8 480 432 480H416C407.2 480 400 472.8 400 464V448C400 439.2 407.2 432 416 432H432z",
  ],
  upload: [
    "M122.6 155.1L240 51.63V368c0 8.844 7.156 16 16 16s16-7.156 16-16V51.63l117.4 104.3C392.4 158.7 396.2 160 400 160c4.406 0 8.812-1.812 11.97-5.375c5.875-6.594 5.25-16.72-1.344-22.58l-144-128c-6.062-5.406-15.19-5.406-21.25 0l-144 128C94.78 137.9 94.16 148 100 154.6S116.1 161.8 122.6 155.1zM448 320h-112c-8.836 0-16 7.162-16 16c0 8.836 7.164 16 16 16H448c17.67 0 32 14.33 32 32v64c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32v-64c0-17.67 14.33-32 32-32h112C184.8 352 192 344.8 192 336C192 327.2 184.8 320 176 320H64c-35.35 0-64 28.65-64 64v64c0 35.35 28.65 64 64 64h384c35.35 0 64-28.65 64-64v-64C512 348.7 483.3 320 448 320zM440 416c0-13.25-10.75-24-24-24s-24 10.75-24 24s10.75 24 24 24S440 429.3 440 416z",
  ],
  cameraRead: [
    "M0 48C0 21.49 21.49 0 48 0H144C152.8 0 160 7.164 160 16C160 24.84 152.8 32 144 32H48C39.16 32 32 39.16 32 48V144C32 152.8 24.84 160 16 160C7.164 160 0 152.8 0 144V48zM320 256C320 291.3 291.3 320 256 320C220.7 320 192 291.3 192 256C192 220.7 220.7 192 256 192C291.3 192 320 220.7 320 256zM256 224C238.3 224 224 238.3 224 256C224 273.7 238.3 288 256 288C273.7 288 288 273.7 288 256C288 238.3 273.7 224 256 224zM325.8 111.6L332 128H360C390.9 128 416 153.1 416 184V328C416 358.9 390.9 384 360 384H152C121.1 384 96 358.9 96 328V184C96 153.1 121.1 128 152 128H180L186.2 111.6C189.7 102.2 198.6 96 208.6 96H303.4C313.4 96 322.3 102.2 325.8 111.6zM152 160C138.7 160 128 170.7 128 184V328C128 341.3 138.7 352 152 352H360C373.3 352 384 341.3 384 328V184C384 170.7 373.3 160 360 160H309.8L297.8 128H214.2L202.2 160H152zM464 0C490.5 0 512 21.49 512 48V144C512 152.8 504.8 160 496 160C487.2 160 480 152.8 480 144V48C480 39.16 472.8 32 464 32H368C359.2 32 352 24.84 352 16C352 7.164 359.2 0 368 0H464zM0 368C0 359.2 7.164 352 16 352C24.84 352 32 359.2 32 368V464C32 472.8 39.16 480 48 480H144C152.8 480 160 487.2 160 496C160 504.8 152.8 512 144 512H48C21.49 512 0 490.5 0 464V368zM368 512C359.2 512 352 504.8 352 496C352 487.2 359.2 480 368 480H464C472.8 480 480 472.8 480 464V368C480 359.2 487.2 352 496 352C504.8 352 512 359.2 512 368V464C512 490.5 490.5 512 464 512H368z",
  ],
  manualEdit: [
    "M32 128V384C32 401.7 46.33 416 64 416H338.2L330.2 448H64C28.65 448 0 419.3 0 384V128C0 92.65 28.65 64 64 64H512C547.3 64 576 92.65 576 128V192C565.1 191.7 554.2 193.6 544 197.6V128C544 110.3 529.7 96 512 96H64C46.33 96 32 110.3 32 128V128zM368 288C376.8 288 384 295.2 384 304C384 312.8 376.8 320 368 320H112C103.2 320 96 312.8 96 304C96 295.2 103.2 288 112 288H368zM96 208C96 199.2 103.2 192 112 192H464C472.8 192 480 199.2 480 208C480 216.8 472.8 224 464 224H112C103.2 224 96 216.8 96 208zM537.5 241.4C556.3 222.6 586.7 222.6 605.4 241.4L622.8 258.7C641.5 277.5 641.5 307.9 622.8 326.6L469.1 480.3C462.9 486.5 455.2 490.8 446.8 492.1L371.9 511.7C366.4 513 360.7 511.4 356.7 507.5C352.7 503.5 351.1 497.7 352.5 492.3L371.2 417.4C373.3 408.9 377.7 401.2 383.8 395.1L537.5 241.4zM582.8 264C576.5 257.8 566.4 257.8 560.2 264L535.3 288.8L575.3 328.8L600.2 303.1C606.4 297.7 606.4 287.6 600.2 281.4L582.8 264zM402.2 425.1L389.1 474.2L439 461.9C441.8 461.2 444.4 459.8 446.4 457.7L552.7 351.4L512.7 311.5L406.5 417.7C404.4 419.8 402.9 422.3 402.2 425.1L402.2 425.1z",
  ],
  folderArrowUp: [
    "M448 96h-176L226.7 50.75C214.7 38.74 198.5 32 181.5 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h384c35.35 0 64-28.65 64-64V160C512 124.7 483.3 96 448 96zM480 416c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V96c0-17.64 14.36-32 32-32h117.5c8.549 0 16.58 3.328 22.63 9.375L258.7 128H448c17.64 0 32 14.36 32 32V416zM267.3 196.7c-6.25-6.25-16.38-6.25-22.62 0l-80 80c-6.25 6.25-6.25 16.38 0 22.62s16.38 6.25 22.62 0L240 246.6V368c0 8.844 7.156 16 16 16s16-7.156 16-16V246.6l52.69 52.69C327.8 302.4 331.9 304 336 304s8.188-1.562 11.31-4.688c6.25-6.25 6.25-16.38 0-22.62L267.3 196.7z",
  ],
  circleCheck: [
    "M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z",
  ],
};

const hoferLogo: IconDefinition = {
  prefix: "hof" as any,
  iconName: "logo" as any,
  icon: [20, 20, [], null, svgPathData.hoferLogo.join(" ")],
};

const packerl: IconDefinition = {
  prefix: "hof" as any,
  iconName: "packerl" as any,
  icon: [24, 24, [], null, svgPathData.packerl.join(" ")],
};

const store: IconDefinition = {
  prefix: "hof" as any,
  iconName: "store" as any,
  icon: [24, 24, [], null, svgPathData.store.join(" ")],
};

const receipt: IconDefinition = {
  prefix: "hof" as any,
  iconName: "receipt" as any,
  icon: [20, 20, [], null, svgPathData.receipt.join(" ")],
};

const qrcode: IconDefinition = {
  prefix: "hof" as any,
  iconName: "qrcode" as any,
  icon: [448, 512, [], null, svgPathData.qrcode.join(" ")],
};

const upload: IconDefinition = {
  prefix: "hof" as any,
  iconName: "upload" as any,
  icon: [512, 512, [], null, svgPathData.upload.join(" ")],
};

const cameraRead: IconDefinition = {
  prefix: "hof" as any,
  iconName: "camera-read" as any,
  icon: [512, 512, [], null, svgPathData.cameraRead.join(" ")],
};

const manualEdit: IconDefinition = {
  prefix: "hof" as any,
  iconName: "manual-edit" as any,
  icon: [640, 512, [], null, svgPathData.manualEdit.join(" ")],
};

const folderArrowUp: IconDefinition = {
  prefix: "hof" as any,
  iconName: "folder-arrow-up" as any,
  icon: [512, 512, [], null, svgPathData.folderArrowUp.join(" ")],
};

const circleCheck: IconDefinition = {
  prefix: "hof" as any,
  iconName: "circle-check" as any,
  icon: [512, 512, [], null, svgPathData.circleCheck.join(" ")],
};

export const fallbackIcon = faBan;

export const icons = [
  hoferLogo, //
  packerl,
  store,
  receipt,
  qrcode,
  upload,
  cameraRead,
  manualEdit,
  folderArrowUp,
  circleCheck,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faBan,
  faCircleNotch,
  faInfoCircle,
  faTimesCircle,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faInfo,
  faLocation,
  faCompass,
  faExclamationCircleLight,
  faHandPointer,
  faMobile,
  faCamera,
  faBackspace,
  faTrash,
];
