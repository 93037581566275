import { getIsStandalone, getUserAgentInfo, IUserAgentInfo } from "./ua-info";

function toSafeClassName(name: string, prefix?: string) {
  const safeName = name.toLocaleLowerCase().replace(/[^a-z0-9]+/g, "-");
  return (prefix ? prefix + "-" : "") + safeName;
}

export function applyUserAgentCssClasses(
  element: HTMLElement = document.body,
  ua: IUserAgentInfo = getUserAgentInfo(),
) {
  // browser-* : chrome, safari, edge, firefox, ...
  element.classList.add(toSafeClassName(ua.browser.name, "browser"));

  // engine-* : blink, gecko, webkit, ...
  element.classList.add(toSafeClassName(ua.engine.name, "engine"));

  // os-* : mac-os, linux, windows, ios, android, ...
  element.classList.add(toSafeClassName(ua.os.name, "os"));

  const isStandalone = getIsStandalone();
  if (isStandalone) {
    element.classList.add("is-standalone");
  }
}
