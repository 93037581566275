import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  templateUrl: "ar-video.modal.html",
  host: { class: "flex flex-auto p-16 backdrop-area" },
})
export class ArVideoModalContent {
  public constructor(public readonly modal: NgbActiveModal) {}
}
