import { ApplicationRef, Component, HostBinding, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SwPush, SwUpdate } from "@angular/service-worker";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { filter, map } from "rxjs/operators";

import { GameInfoService } from "./services/game-info";

@UntilDestroy()
@Component({
  selector: "app-root",
  template: `<!-- <template> -->
    <app-svg-symbol-definitions></app-svg-symbol-definitions>
    <ng-progress></ng-progress>
    <router-outlet></router-outlet>
    <!-- </template> -->`,
  host: { class: "flex flex-col flex-auto min-h-full children-flex-auto" },
})
export class AppRoot implements OnInit {
  @HostBinding("class.is-coupon-day")
  public isCouponDay: boolean;

  public constructor(
    private readonly _appRef: ApplicationRef, //
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _title: Title,
    private readonly _swUpdate: SwUpdate,
    private readonly _swPush: SwPush,
    private readonly _gameInfo: GameInfoService,
  ) {}

  public async ngOnInit() {
    this._swUpdate.available //
      .subscribe(async ({ current, available }) => {
        await this._swUpdate.activateUpdate();
      });
    this._swUpdate.activated //
      .subscribe(({ current, previous }) => {
        window.location.reload();
      });

    if (this._swUpdate.isEnabled) {
      await this._swUpdate.checkForUpdate();
    }

    // -----

    this._gameInfo.isCouponDay$.subscribe((s) => (this.isCouponDay = s));

    // -----

    const baseTitle = this._title.getTitle();
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this._route.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          const t = child.snapshot.data?.["title"] as string;
          return (t ? t + " - " : "") + baseTitle;
        }),
      )
      .pipe(untilDestroyed(this))
      .subscribe((t) => this._title.setTitle(t));
  }
}
