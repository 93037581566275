import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";

import { BaseUrlPipe } from "./pipes/base-url.pipe";

export * from "./pipes/base-url.pipe";

const PIPES = [
  BaseUrlPipe, //
];

@NgModule({
  imports: [
    HttpClientModule, //
  ],

  declarations: [
    ...PIPES, //
  ],

  providers: [],

  exports: [
    HttpClientModule, //
    ...PIPES,
  ],
})
export class RsNgHttpModule {}
