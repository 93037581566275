import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import {
  addMilliseconds,
  differenceInMilliseconds,
  differenceInSeconds,
  formatISO,
  isBefore,
  isSameDay,
  startOfDay,
} from "date-fns";
import { combineLatest, timer } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, finalize, map } from "rxjs/operators";

import { environment } from "../environment";
import { IAdventCalendarDayLite, IAdventCalendarInfo } from "../interfaces";
import { AdventCalendarApiService, DEFAULT_GAME_END_TIME, DEFAULT_GAME_START_TIME } from "./advent-calendar.api";

export enum GameStatus {
  Undefined = "undefined",
  Waiting = "waiting",
  Running = "running",
  Ended = "ended",
}

export interface IGameInfoState {
  calendarInfo: IAdventCalendarInfo;
  calendarInfoTimeOffset: number;
  startTime: Date;
  endTime: Date;
  currentTime: Date;
  status: GameStatus;
  statusDuration: {
    days: number;
    hours: number;
    minutes: number;
  };
  days: IAdventCalendarDayLite[];
  daysLoading: boolean;
}

const dummyDays = [...Array(24).keys()].map((i) => ({ day: i + 1 } as IAdventCalendarDayLite));
const calendarDayPatches = environment.config.calendarDayPatches;

@Injectable()
export class GameInfoStore extends ComponentStore<IGameInfoState> {
  //#region Properties

  public readonly calendarInfo$ = this.select((state) => state.calendarInfo);

  public readonly calendarInfoTimeOffset$ = this.select((state) => state.calendarInfoTimeOffset);

  public readonly startTime$ = this.select((state) => state.startTime);

  public readonly endTime$ = this.select((state) => state.endTime);

  public readonly currentTime$ = this.select((state) => state.currentTime);

  public readonly status$ = this.select((state) => state.status);

  public readonly statusDuration$ = this.select((state) => state.statusDuration);

  public readonly waitingMessage$ = this.select((state) => state.calendarInfo?.messages?.countdown);

  public readonly endedMessage$ = this.select((state) => state.calendarInfo?.messages?.after);

  public readonly copMessage$ = this.select((state) => state.calendarInfo?.messages?.toc);

  public readonly arAvailable$ = this.select((state) => false /* !!state.calendarInfo?.arAvailable */);

  public readonly days$ = this.select((state) => state.days);

  public readonly daysLoading$ = this.select((state) => !!state.daysLoading);

  public get currentTime() {
    return this.get((state) => state.currentTime);
  }

  //#endregion

  //#region Constructor

  public constructor() {
    super({
      calendarInfoTimeOffset: 0,
      startTime: new Date(DEFAULT_GAME_START_TIME),
      endTime: new Date(DEFAULT_GAME_END_TIME),
      status: GameStatus.Undefined,
    } as IGameInfoState);
  }

  //#endregion

  //#region Update

  public readonly updateCalendarInfo = this.updater((state, calendarInfo: IAdventCalendarInfo) => ({
    ...state,
    calendarInfo,
    startTime: calendarInfo?.startTime as Date,
    endTime: calendarInfo?.endTime as Date,
  }));

  public readonly updateTimeOffset = this.updater((state, timeOffset: number) => ({
    ...state,
    calendarInfoTimeOffset: timeOffset,
  }));

  public readonly updateDays = this.updater((state, days: IAdventCalendarDayLite[]) => ({
    ...state,
    days,
  }));

  public readonly updateDaysLoading = this.updater((state, daysLoading: boolean) => ({
    ...state,
    daysLoading,
  }));

  //#endregion
}

@Injectable()
export class GameInfoService {
  //#region Properties

  public readonly currentTime$ = this._store.currentTime$;

  public readonly currentDay$ = this._store.currentTime$ //
    .pipe(map((time) => startOfDay(time)))
    .pipe(distinctUntilChanged((p, c) => Number(p) == Number(c)));

  public readonly status$ = this._store.status$;

  public readonly statusDuration$ = this._store.statusDuration$;

  public readonly waitingMessage$ = this._store.waitingMessage$;

  public readonly endedMessage$ = this._store.endedMessage$;

  public readonly copMessage$ = this._store.copMessage$;

  public readonly days$ = this._store.days$;

  public readonly daysLoading$ = this._store.daysLoading$;

  public readonly isCouponDay$ = combineLatest([this.currentTime$, this.days$])
    .pipe(filter(([currentTime, days]) => !!currentTime && !!days?.length))
    .pipe(
      map(([currentTime, days]) => {
        const currentDate = formatISO(currentTime, { representation: "date" });
        const currentDay = days.find((d) => {
          if (d.date instanceof Date) {
            return formatISO(d.date, { representation: "date" }) === currentDate;
          }
          return false;
        });
        return currentDay?.coupon;
      }),
    )
    .pipe(distinctUntilChanged());

  public get currentTime() {
    return this._store.currentTime;
  }

  //#endregion

  //#region Constructor

  public constructor(
    private readonly _store: GameInfoStore, //
    private readonly _api: AdventCalendarApiService,
  ) {
    // void this._timer;
    this._initialize();
  }

  //#endregion

  //#region Game Info

  private _initialize() {
    this._store.calendarInfo$.subscribe((info) => {
      let timeOffset = 0;
      if (info?.currentTime) {
        const apiCurrentTime = info.currentTime as Date;
        const currentTime = new Date();
        const currentTimeOffset = differenceInMilliseconds(apiCurrentTime, currentTime);
        if (Math.abs(currentTimeOffset) > 30000) {
          timeOffset = currentTimeOffset;
        }
      }
      this._store.updateTimeOffset(timeOffset);
    });

    this._refreshCalendarInfo();

    combineLatest([
      this._store.calendarInfo$, //
      this._store.calendarInfoTimeOffset$,
      this._store.startTime$,
      this._store.endTime$,
      timer(0, 1000),
    ])
      .pipe(debounceTime(100))
      .subscribe(([info, timeOffset, startTime, endTime, _]) => {
        if (info) {
          this._refreshStatus(this._getCurrentTime(timeOffset), startTime, endTime);
        }
      });

    // Days -----
    this._store.updateDaysLoading(true);
    this._store.updateDays(this._patchDays(dummyDays));

    this.status$.pipe(filter((status) => status === GameStatus.Running)).subscribe(() => {
      this._store.updateDaysLoading(true);
      this._api
        .getDays()
        .pipe(finalize(() => this._store.updateDaysLoading(false)))
        .subscribe((days) => this._store.updateDays(this._patchDays(days)));
    });

    this.status$.subscribe((status) => {
      if (status === GameStatus.Waiting || status === GameStatus.Ended) {
        this._store.updateDaysLoading(false);
      }
    });
  }

  private _refreshCalendarInfo() {
    this._api.getCalendarInfo().subscribe((info) => this._store.updateCalendarInfo(info));
  }

  private _refreshStatus(currentTime: Date, startTime: Date, endTime: Date) {
    let compareTime = endTime;
    let status = GameStatus.Ended;

    if (isBefore(currentTime, startTime)) {
      status = GameStatus.Waiting;
      compareTime = startTime;
    } else if (isBefore(currentTime, endTime)) {
      status = GameStatus.Running;
    }

    const diffSecs = Math.abs(differenceInSeconds(compareTime, currentTime));

    const statusDuration = {
      days: Math.floor(diffSecs / (24 * 60 * 60)),
      hours: Math.floor(diffSecs / (60 * 60)) % 24,
      minutes: Math.floor(diffSecs / 60) % 60,
      seconds: Math.floor(diffSecs) % 60,
    };

    this._store.setState((state) => ({
      ...state,
      currentTime,
      status,
      statusDuration,
    }));
  }

  private _getCurrentTime(timeOffset: number) {
    return addMilliseconds(new Date(), timeOffset);
  }

  private _patchDays(days: IAdventCalendarDayLite[], apiDays: IAdventCalendarDayLite[] = []) {
    days.forEach((d) => {
      const p = calendarDayPatches.find((x) => x.day === d.day);
      const a = apiDays?.find((x) => x.day === d.day);
      Object.assign(d, a ?? {}, p ?? {});
    });
    return days;
  }

  //#endregion
}
