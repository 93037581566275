import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { getDate, getMonth } from "date-fns";
import { filter, map } from "rxjs/operators";

import { GameInfoService } from "../services/game-info";

@Injectable()
export class WinRedirectCanActivateGuard implements CanActivate {
  public constructor(private _router: Router, private readonly _gameInfo: GameInfoService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._gameInfo.currentTime$ //
      .pipe(filter((currentTime) => !!currentTime))
      .pipe(
        map((currentTime) => {
          if (getMonth(currentTime) == 11) {
            return this._router.createUrlTree(["/tag", Math.min(getDate(currentTime), 24)]);
          }
          return this._router.createUrlTree(["/"]);
        }),
      );
  }
}
