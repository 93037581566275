import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { isSameDay } from "date-fns";
import { GameInfoService } from "../../services/game-info";

import { IAdventCalendarDay, IAdventCalendarWinResponseError } from "../../interfaces";
import { links } from "../../links";
import { AdventCalendarApiService } from "../../services/advent-calendar.api";
import { LocalStorageService } from "../../services/local-storage";
import { $formatErrors } from "../../utilities/error";

import { ImageDataHelper } from "@resee/image-data/index";
import { BarcodeDetector, BarcodeFormat } from "@resee/barcode-detector/src/index";
import { decodeAndVerifyReceiptData } from "@various/rksv-hofer/src/index";

const SAVE_WIN_FORM_DATA_KEY = "saveWinFormData";
const WIN_FORM_DATA_KEY = "winFormData";

const IMask = window.IMask as any;

@Component({
  selector: "app-win-form",
  templateUrl: "win-form.html",
  host: { class: "block place-self-start" },
})
export class WinFormComponent implements OnInit {
  //#region Properties

  public readonly self = this;

  public readonly links = links;

  @Input()
  public day: IAdventCalendarDay;

  public readonly form = this._fb.group({
    // TODO [SR]: Check other possible client side validations (!)
    salutation: ["-"],
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    mail: ["", Validators.required],
    birthday: [""],
    subscriptions: this._fb.group({
      // hofer: [false],
      // reisen: [false],
    }),
    couponCode: ["", Validators.required],
  });

  public readonly acceptConditionsOfParticipation = new FormControl(false);

  public readonly acceptDataPrivacyStatement = new FormControl(false);

  public readonly acceptManualReceipt = new FormControl(true);

  public saveFormData = false;

  public requestCompleted = false;

  public showHint = false;

  public optionsVisible = false;

  public optionsPopup = false;

  public toggleShowHint() {
    this.showHint = !this.showHint;
  }

  public priceMask = {
    mask: Number,
    scale: 2,
    signed: false,
    thousandsSeparator: " ",
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ",",
    mapToRadix: ["."],
    min: 0,
    max: 9999.99,
    defaultValue: 0,
  };

  public receiptNoMask = {
    mask: "{\\*}0000 `000/`000/`000/`###",
    definitions: {
      "#": /[0-9X]/,
    },
    lazy: false,
    overwrite: true,
  };

  public dateTimeMask = {
    mask: Date,
    pattern: "d{.}`m{.}`y `h{:}`i",
    blocks: {
      d: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
      },
      m: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
      },
      y: {
        mask: "00",
      },
      h: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 23,
      },
      i: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
      },
    },
    format: function (date: Date) {
      const y = String(date.getFullYear() - 2000).padStart(2, "0");
      const m = String(date.getMonth() + 1).padStart(2, "0");
      const d = String(date.getDate()).padStart(2, "0");
      const h = String(date.getHours()).padStart(2, "0");
      const i = String(date.getMinutes()).padStart(2, "0");
      return `${d}.${m}.${y} ${h}:${i}`;
    },
    parse: function (str: string) {
      const [date, time] = str.split(" ");
      const [d, m, y] = date.split(".");
      const [h, i] = time.split(":");
      return new Date(Number(y) + 2000, Number(m) - 1, Number(d), Number(h), Number(i));
    },
    // min: new Date(2022, 11, 1, 0, 0),
    // max: new Date(2022, 11, 24, 23, 59),
    autofix: true,
    lazy: false,
    overwrite: true,
  };

  public receiptData = { receiptNo: null, dateTime: null, price: null };

  public hasValidReceiptData = false;

  public hasValidReceiptInfo = false;

  public isScannedReceipt = false;

  public isManualReceipt = false;

  public showManualEnter = false;

  public failedUploadCount = 0;

  public onAcceptManualReceiptValue(field: string, value: any) {
    this.isScannedReceipt = false;
    this.receiptData[field] = value;
    const { receiptNo, dateTime, price } = this.receiptData as any;
    const manualReceiptString = `${receiptNo || "*_"} ${dateTime || "_"} : ${price || "_"}`;
    this.hasValidReceiptData = manualReceiptString.indexOf("_") == -1;
    if (this.hasValidReceiptData) {
      const dp = dateTime.split(" ")[0].split(".");
      const isDateValid = dp[1] == 12 && dp[2] == 23;
      this.hasValidReceiptInfo = isDateValid;
    } else {
      this.hasValidReceiptInfo = false;
    }
    const { couponCode } = this.form.controls;
    couponCode.setValue(manualReceiptString);
    this.couponCodeMode = "manual";
  }

  public resetReceipt(reason = null) {
    if (this.abortTimeout) {
      clearTimeout(this.abortTimeout);
      this.abortTimeout = null;
    }
    this.scanning = false;
    this.permissionState = null;
    this.hasValidReceiptData = false;
    this.hasValidReceiptInfo = false;
    this.isScannedReceipt = false;
    this.isManualReceipt = false;
    this.optionsVisible = false;
    this.optionsPopup = false;
    this.showManualEnter = false;
    this.failedUploadCount = 0;
    this.couponCodeMode = null;
    this.receiptData["receiptNo"] = null;
    this.receiptData["price"] = null;
    this.receiptData["dateTime"] = null;

    const { couponCode } = this.form.controls;
    couponCode.reset();
    couponCode.setErrors(null);
    couponCode.updateValueAndValidity();

    this.acceptManualReceipt.setValue(true);

    if (reason == "scan-abort") {
      this.showManualEnter = true;
    }
  }

  public enterDataManually() {
    this.resetReceipt();
    this.isManualReceipt = true;
    // this.acceptManualReceipt.setValue(false);
    this.acceptManualReceipt.setValue(true);
  }

  //#endregion

  //#region Constructor

  public constructor(
    private readonly _fb: FormBuilder, //
    private readonly _api: AdventCalendarApiService,
    private readonly _localStorage: LocalStorageService,
    public readonly gameInfo: GameInfoService,
  ) {}

  //#endregion

  public async ngOnInit() {
    this.saveFormData = await this._localStorage.get<boolean>(SAVE_WIN_FORM_DATA_KEY, false);
    if (this.saveFormData) {
      const formData = await this._localStorage.get<any>(WIN_FORM_DATA_KEY, {});
      const patchedFormData = Object.assign(formData, { couponCode: "" });
      this.form.setValue(patchedFormData);
    }
  }

  //#region Form Methods

  private abortTimeout = null;

  public startScan() {
    this.resetReceipt();
    this.scanning = true;
    this.abortTimeout = setTimeout(() => this.resetReceipt("scan-abort"), 60000);
  }

  public scanning = false;
  public reading = false;

  public permissionState = null;

  public couponCodeMode = null;

  public async onFileSelected($event: Event) {
    const file: File | null = ($event.target as HTMLInputElement).files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", async (e) => {
        try {
          this.reading = true;
          const imageData = await new ImageDataHelper().getImageDataFromUrl(e.target.result as string);

          const barcodeDetector = new BarcodeDetector({ formats: [BarcodeFormat.qr_code] });
          const results = await barcodeDetector.detect(imageData);

          if (results[0]?.rawValue) {
            await this.setReceiptData(results[0]?.rawValue, "upload");
          } else {
            this.failedUploadCount++;
            const { couponCode } = this.form.controls;
            couponCode.setValue("_");
            couponCode.markAsDirty();
            couponCode.setErrors({
              api: "Der Kassenbon konnte leider nicht gelesen werden. Bitte versuchen Sie es erneut.",
            });
            this.form.updateValueAndValidity();
            this.showManualEnter = true;
          }
        } catch (err) {
          this.failedUploadCount++;
          const { couponCode } = this.form.controls;
          couponCode.setValue("_");
          couponCode.markAsDirty();
          couponCode.setErrors({
            api: "Der Kassenbon konnte leider nicht gelesen werden. Bitte versuchen Sie es erneut.",
          });
          this.form.updateValueAndValidity();
          this.showManualEnter = true;
        } finally {
          this.reading = false;
          if (this.failedUploadCount >= 1) {
            this.resetReceipt("scan-abort");
          }
        }
      });
      reader.readAsDataURL(file);
    }
  }

  public async setReceiptData(data: string, mode = "camera") {
    try {
      const { receiptNoFull, dateTime, dateTimeFormatted, sumTotalFormatted } = await decodeAndVerifyReceiptData(data);
      this.resetReceipt();
      this.receiptData["receiptNo"] = receiptNoFull;
      this.receiptData["price"] = sumTotalFormatted;
      this.receiptData["dateTime"] = dateTimeFormatted;
      const { couponCode } = this.form.controls;
      couponCode.setValue(data);
      this.isScannedReceipt = true;
      this.hasValidReceiptData = true;
      const isDateValid = dateTime.getMonth() == 11 && dateTime.getFullYear() == 2024;
      this.hasValidReceiptInfo = isDateValid;
      this.couponCodeMode = mode;
    } catch (e) {
      /* ignore */
    }
  }

  public async submit() {
    this.acceptConditionsOfParticipation.markAllAsTouched();
    this.acceptDataPrivacyStatement.markAllAsTouched();
    this.acceptManualReceipt.markAllAsTouched();
    this.form.markAllAsTouched();

    await this._localStorage.set(SAVE_WIN_FORM_DATA_KEY, this.saveFormData);

    const { mail, firstName, lastName, couponCode } = this.form.controls;

    const isCouponForm = this.isCouponForm(this.day);
    if (!isCouponForm) {
      couponCode.setValue("?");
    }

    if (mail.errors?.["api"]) {
      mail.setErrors(null);
      this.form.updateValueAndValidity();
    }

    if (firstName.errors?.["api"]) {
      firstName.setErrors(null);
      this.form.updateValueAndValidity();
    }

    if (lastName.errors?.["api"]) {
      lastName.setErrors(null);
      this.form.updateValueAndValidity();
    }

    if (couponCode.errors?.["api"]) {
      couponCode.setErrors(null);
      this.form.updateValueAndValidity();
    }

    const copAccepted = !!this.acceptConditionsOfParticipation.value;
    const dpsAccepted = !!this.acceptDataPrivacyStatement.value;
    const mrAccepted = !!this.acceptManualReceipt.value;
    if (!copAccepted || !dpsAccepted || !mrAccepted || this.form.invalid) {
      this._scrollFormIntoView();
      return;
    }

    const value = this.form.value;

    if (this.saveFormData) {
      await this._localStorage.set(WIN_FORM_DATA_KEY, value);
    } else {
      await this._localStorage.set(WIN_FORM_DATA_KEY, null);
    }

    this.acceptConditionsOfParticipation.disable();
    this.acceptDataPrivacyStatement.disable();
    this.acceptManualReceipt.disable();
    this.form.disable();

    // const formDate = this.day?.date as Date;
    const requestObj = Object.assign({ coupon: isCouponForm }, value);
    if (!isCouponForm) {
      delete requestObj["couponCode"];
    } else {
      requestObj["couponCodeMode"] = this.couponCodeMode;
    }
    try {
      await this._api.win(requestObj).toPromise();
      // == {"success": true, "message": "User subscribed"}
    } catch (e /* : HttpErrorResponse */) {
      if ((e as any).status != 200) {
        const { errors, message } = ((e as any).error as IAdventCalendarWinResponseError) ?? ({} as any);
        this.acceptConditionsOfParticipation.enable();
        this.acceptDataPrivacyStatement.enable();
        this.acceptManualReceipt.enable();
        this.form.enable();
        mail.updateValueAndValidity();
        firstName.updateValueAndValidity();
        lastName.updateValueAndValidity();
        couponCode.updateValueAndValidity();
        if (message == "No Normal-Participation on Coupon-Days allowed") {
          mail.setErrors({
            api: "Die Teilnahme ist nur mit gültigem Kassenbon möglich.",
          });
        } else if (message == "CouponCode required for Participation") {
          couponCode.setErrors({
            api: "Der QR-Code Inhalt des Kassenbon wird benötigt.",
          });
        } else {
          if (errors?.mail) {
            mail.setErrors({
              api: errors.mail[0] ?? "Ein unerwarteter Fehler ist aufgetreten, bitte versuchen Sie es erneut.",
            });
          }
          if (errors?.firstName) {
            firstName.setErrors({
              api: errors.firstName[0] ?? "Ein unerwarteter Fehler ist aufgetreten, bitte versuchen Sie es erneut.",
            });
          }
          if (errors?.lastName) {
            lastName.setErrors({
              api: errors.lastName[0] ?? "Ein unerwarteter Fehler ist aufgetreten, bitte versuchen Sie es erneut.",
            });
          }
          if (errors?.couponCode) {
            // "Der Einkaufsbon wurde bereits verwendet. Bon kann nur einmal im gesamten Gewinnspielzeitraum f\u00fcr die Teilnahme verwendet werden."
            // "Der Einkaufsbon wurde nicht erkannt."
            // "Es sind nur Einkaufsbon aus Dezember 2022 gültig."
            couponCode.setErrors({
              api: errors.couponCode[0] ?? "Ein unerwarteter Fehler ist aufgetreten, bitte versuchen Sie es erneut.",
            });
          }
        }
        mail.markAsDirty();
        this._scrollFormIntoView();
        return;
      }
    }
    this.requestCompleted = true;
    this._scrollFormIntoView();
  }

  //#endregion

  //#region Pipe Functions

  public readonly $formatErrors = $formatErrors;

  //#endregion

  //#region Utility Methods

  private _scrollFormIntoView() {
    const winForm = document.getElementById("app-win-form-anchor");
    winForm?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  public showOptions() {
    this.optionsVisible = true;
    this.optionsPopup = true;
  }

  public closeOptions() {
    this.resetReceipt("scan-abort");
  }

  //#endregion

  //#region Pipe Functions

  public isCouponForm(day: IAdventCalendarDay) {
    return !!day.coupon;
  }

  public isSameDay(currentTime: Date, day: Date) {
    return currentTime && day instanceof Date && isSameDay(currentTime, day);
  }

  //#endregion
}
