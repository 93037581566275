import smoothscroll from "smoothscroll-polyfill";

self["__Zone_disable_requestAnimationFrame"] = true;
self["__Zone_ignore_on_properties"] = [
  {
    target: window,
    ignoreProperties: ["message"],
  },
  {
    target: HTMLElement.prototype,
    ignoreProperties: [],
  },
];

require("zone.js/dist/zone");

smoothscroll.polyfill();
