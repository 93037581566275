import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { INewsletterSubscribeRequest, INewsletterSubscribeResponse } from "../interfaces";

@Injectable()
export class NewsletterApiService {
  public constructor(private readonly http: HttpClient) {}

  public subscribe(body: INewsletterSubscribeRequest) {
    return this.http.post<INewsletterSubscribeResponse>(`api://v1/advent-calendar/newsletter/subscribe`, body);
  }
}
