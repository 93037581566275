import { Component } from "@angular/core";
import { links } from "../links";

@Component({
  selector: "app-rund-um-weihnachten",
  host: { class: "" },
  template: `<!-- <template> -->
    <div class="xl:container">
      <div class="py-32 text-center text-3xl lg:text-4xl">
        <h1>
          <span class="block font-bold"> Alles rund um </span>
          <span class="block"> Weihnachten </span>
        </h1>
      </div>
      <div class="flex flex-col md:flex-row">
        <div class="mb-16 flex-auto md:mb-0 md:mr-8">
          <a
            [href]="l.link"
            *ngIf="links.rundUmWeihnachten1 as l"
            target="_blank"
            class="bg-img-gold_ hover:scale-98 block transform p-0 shadow-sm transition-all duration-200 hover:shadow-lg"
          >
            <img src="/assets/advent-calendar/dummy_988x748.png?v2" draggable="false" [alt]="l.label" />
          </a>
        </div>

        <div class="mb-16 flex-auto md:mb-0 md:ml-8 md:mr-8">
          <a
            [href]="l.link"
            *ngIf="links.rundUmWeihnachten2 as l"
            target="_blank"
            class="bg-img-gold_ hover:scale-98 block transform p-0 shadow-sm transition-all duration-200 hover:shadow-lg"
          >
            <img src="/assets/advent-calendar/dummy_988x748.png?v2" draggable="false" [alt]="l.label" />
          </a>
        </div>

        <div class="flex-auto md:ml-8">
          <a
            [href]="l.link"
            *ngIf="links.rundUmWeihnachten3 as l"
            target="_blank"
            class="bg-img-gold_ hover:scale-98 block transform p-0 shadow-sm transition-all duration-200 hover:shadow-lg"
          >
            <img src="/assets/advent-calendar/dummy_988x748.png?v2" draggable="false" [alt]="l.label" />
          </a>
        </div>
      </div>

      <div>
        <div class="mt-16 flex-auto">
          <a
            [href]="l.link"
            *ngIf="links.rundUmWeihnachten4 as l"
            target="_blank"
            class="bg-img-gold_ hover:scale-98 block transform p-0 shadow-sm transition-all duration-200 hover:shadow-lg"
          >
            <img
              src="/assets/advent-calendar/dummy_930x660.png?v2"
              draggable="false"
              [alt]="l.label"
              class="md:hidden"
            />
            <img
              src="/assets/advent-calendar/dummy_3004x748.png?v2"
              draggable="false"
              [alt]="l.label"
              class="hidden md:block"
            />
          </a>
        </div>
      </div>
    </div>
    <!-- </template> -->`,
})
export class RundUmWeihnachtenComponent {
  public readonly links = links;
}
