import { isReady } from "./ready-check";

export interface IImageDataOptions {
  ensureLandscape: boolean;
}

export class ImageDataHelper {
  //#region Properties

  private readonly canvas = document.createElement("canvas");

  //#endregion

  //#region Size

  public async getActualSize(mediaElement: HTMLVideoElement | HTMLImageElement) {
    await isReady(mediaElement);
    if (this.isVideoElement(mediaElement)) {
      return { width: mediaElement.videoWidth ?? 0, height: mediaElement.videoHeight ?? 0 };
    }
    return { width: mediaElement.naturalWidth ?? 0, height: mediaElement.naturalHeight ?? 0 };
  }

  public async getSize(mediaElement: HTMLVideoElement | HTMLImageElement, options?: IImageDataOptions) {
    const { width, height } = await this.getActualSize(mediaElement);
    if (options?.ensureLandscape && this.isPortrait(width, height)) {
      return { width: height, height: width };
    }
    return { width, height };
  }

  //#endregion

  //#region Image Data

  public async getImageData(mediaElement: HTMLVideoElement | HTMLImageElement, options?: IImageDataOptions) {
    let { width, height } = await this.getActualSize(mediaElement);
    if (width === 0 || height === 0) {
      return null;
    }
    const context2d = this.canvas.getContext("2d");
    if (options?.ensureLandscape && this.isPortrait(width, height)) {
      [width, height] = [height, width];
      this.ensureCanvasDimensions(width, height);
      context2d.save();
      try {
        context2d.translate(width, 0);
        context2d.rotate(Math.PI / 2);
        context2d.drawImage(mediaElement, 0, 0, height, width);
      } finally {
        context2d.restore();
      }
    } else {
      this.ensureCanvasDimensions(width, height);
      context2d.drawImage(mediaElement, 0, 0, width, height);
    }
    return context2d.getImageData(0, 0, width, height);
  }

  public async getImageDataFromUrl(url: string) {
    const img = new Image();
    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
      img.src = url;
    });
    const width = img.naturalWidth;
    const height = img.naturalHeight;
    this.ensureCanvasDimensions(width, height);
    const context2d = this.canvas.getContext("2d");
    context2d.drawImage(img, 0, 0, width, height);
    return context2d.getImageData(0, 0, width, height);
  }

  //#endregion

  //#region Helper Methods

  public isPortrait(width: number, height: number) {
    return width < height;
  }

  public getOrientation(width: number, height: number): "portrait" | "landscape" {
    return this.isPortrait(width, height) ? "portrait" : "landscape";
  }

  public cloneImageData({ data, width, height }: ImageData): ImageData {
    return new ImageData(Uint8ClampedArray.from(data), width, height);
  }

  private ensureCanvasDimensions(width: number, height: number) {
    const { width: cw, height: ch } = this.canvas;
    if (cw !== width) {
      this.canvas.width = width;
    }
    if (ch !== height) {
      this.canvas.height = height;
    }
  }

  private isVideoElement(mediaElement: HTMLVideoElement | HTMLImageElement): mediaElement is HTMLVideoElement {
    return mediaElement instanceof HTMLVideoElement;
  }

  //#endregion
}
