import { elements, shuffle } from "./utils";

export interface IBlockGridElement {
  size: number[];
}

export interface IBlockGridSlot<T extends IBlockGridElement> {
  element?: T;
}

export interface IBlockGridConfig {
  numOfBlocks: number;
  blockInfo: {
    width: number;
    height: number;
  };
}

function getNeededSpace(element: IBlockGridElement) {
  const {
    size: [w, h],
  } = element;
  return w * h;
}

export class BlockGrid<T extends IBlockGridElement = any> {
  public readonly numOfSlots: number;
  public readonly blocks: IBlockGridSlot<T>[][][] = [];

  public constructor({ numOfBlocks, blockInfo }: IBlockGridConfig) {
    this.numOfSlots = numOfBlocks * blockInfo.width * blockInfo.height;
    for (let i = 0; i < numOfBlocks; i++) {
      const block = [];
      for (let j = 0; j < blockInfo.height; j++) {
        block.push(elements(blockInfo.width, () => ({})));
      }
      this.blocks.push(block);
    }
  }

  public placeElements(elements: T[], key: string, blockMap: any[][][]) {
    for (let b = 0; b < blockMap.length; b++) {
      const block = blockMap[b];
      for (let r = 0; r < block.length; r++) {
        const row = block[r];
        for (let c = 0; c < row.length; c++) {
          const element = elements.find((e) => e[key] === row[c]);
          this.blocks[b][r][c].element = element;
        }
      }
    }
  }

  public tryPlaceElements(elements: T[], mode = "random") {
    const shuffledAndSorted = shuffle(elements.slice()) //
      .sort((a, b) => getNeededSpace(b) - getNeededSpace(a));

    let bs: number[] = [];
    for (const e of shuffledAndSorted) {
      if (bs.length < this.blocks.length) {
        bs = [...bs, ...shuffle(this.blocks.map((_, i) => i))];
      }

      let block: BlockGrid<T>["blocks"][0];
      let placed = false;
      do {
        block = this.blocks[bs.pop()];
        placed = this._tryPlaceElementRandom(e, block);
      } while (!placed && block);

      if (!placed) {
        this._resetPlacements();
        return false;
      }
    }
    return true;
  }

  private _tryPlaceElementRandom(element: T, block: BlockGrid<T>["blocks"][0]) {
    if (block == null) {
      return false;
    }

    const { size } = element;
    const rows = block.length;
    const cols = block[0].length;

    const rs = shuffle(elements(rows - size[1] + 1, (i) => i));
    const cs = shuffle(elements(cols - size[0] + 1, (i) => i));

    for (const r of rs) {
      next: for (const c of cs) {
        const slots: IBlockGridSlot<T>[] = [];
        for (let x = 0; x < size[0]; x++) {
          for (let y = 0; y < size[1]; y++) {
            const slot = block[r + y][c + x];
            if (slot.element) {
              continue next;
            }
            slots.push(slot);
          }
        }
        slots.forEach((s) => (s.element = element));
        return true;
      }
    }
    return false;
  }

  private _resetPlacements() {
    // TODO [SR]: Clear all placements (!), e.g. for retry
  }

  public toString(elToString?: (e: T) => string) {
    const s = (c: IBlockGridSlot<T>) => elToString?.(c?.element) ?? c?.element;
    return this.blocks.map((b) => b.map((r) => r.map((c) => s(c)).join("")).join("\n")).join("\n\n");
  }
}
