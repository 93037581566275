import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy } from "@ngneat/until-destroy";
import { isSameDay } from "date-fns";
import { GameInfoService } from "../../services/game-info";

import { environment } from "../../environment";
import { IAdventCalendarDayLite } from "../../interfaces";
import { BlockGrid, IBlockGridConfig } from "../../lib/calendar-grid";
import { AdventCalendarApiService } from "../../services/advent-calendar.api";

const RATIO_SPACER_IMAGE =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAECAQAAADhJE2MAAAADklEQVR42mNkAANGbBQAAGIABUN3+8IAAAAASUVORK5CYII=";

const gridConfig: IBlockGridConfig = {
  numOfBlocks: 4,
  blockInfo: {
    width: 3,
    height: 3,
  },
};

@UntilDestroy()
@Component({
  selector: "app-advent-calendar",
  templateUrl: "advent-calendar.html",
  host: { class: "flex flex-auto" },
})
export class AdventCalendarComponent implements OnInit {
  //#region Properties

  public readonly self = this;

  public readonly ratioSpacerImage = RATIO_SPACER_IMAGE;

  // public readonly grid: BlockGrid<IAdventCalendarDayLite>;

  public readonly templateAreas = {
    xs: "",
    sm: "",
    lg: "",
  };

  public isOpening: boolean;

  //#endregion

  //#region Constructor

  public constructor(
    private readonly _router: Router, //
    private readonly _api: AdventCalendarApiService,
    public readonly gameInfo: GameInfoService,
    private readonly _modal: NgbModal,
  ) {
    const grid = this._buildGrid(environment.config.calendarLayoutMap);
    const gridMobile = this._buildGrid(environment.config.calendarLayoutMapMobile);
    this.templateAreas.lg = this._generateCssGridTemplate(grid, 12);
    this.templateAreas.sm = this._generateCssGridTemplate(grid, 6);
    this.templateAreas.xs = this._generateCssGridTemplate(gridMobile, 3);
  }

  //#endregion

  //#region Lifecycle Hooks

  public async ngOnInit() {
    // ...
  }

  //#endregion

  //#region Advent Calendar

  private _buildGrid(calendarLayoutMap: number[][][]) {
    const grid = new BlockGrid<IAdventCalendarDayLite>(gridConfig);
    // grid.placeElements(environment.config.calendarDayPatches as IAdventCalendarDayLite[], "day", calendarLayoutMap);
    grid.tryPlaceElements(environment.config.calendarDayPatches as IAdventCalendarDayLite[]);
    return grid;
  }

  private _generateCssGridTemplate(grid: BlockGrid<IAdventCalendarDayLite>, a: number) {
    const COLS = 3;
    const ROWS = 3;
    const accessors: [number, number, number][][] = [];
    let cur: [number, number, number][];
    for (let i = 0; i < grid.numOfSlots; i++) {
      if (i % a === 0) {
        accessors.push((cur = []));
      }
      const b = Math.floor((i % a) / COLS) + Math.floor(i / (ROWS * a)) * (a / COLS);
      const r = Math.floor(i / a) % COLS;
      const c = i % COLS;
      cur.push([b, r, c]);
    }
    return accessors
      .map(
        (r) =>
          '"' +
          r
            .map((a) => {
              const day = grid.blocks[a[0]][a[1]][a[2]]?.element;
              return this._getCssDayLabel(day);
            })
            .join(" ") +
          '"',
      )
      .join("\n");
  }

  private _getCssDayLabel(day: IAdventCalendarDayLite) {
    return "d-" + String(day?.day).padStart(2, "0");
  }

  public async openDay(day: IAdventCalendarDayLite, currentTime: Date, event: MouseEvent) {
    event?.stopPropagation();
    event?.preventDefault();

    if (this.isOpening || !this.canBeOpened(day, currentTime)) {
      return;
    }

    this.isOpening = true;
    day["isOpen"] = !day["isOpen"];

    setTimeout(async () => {
      try {
        await this._router.navigate(["/tag", day.day]);
      } finally {
        day["isOpen"] = false;
        this.isOpening = false;
      }
    }, 500);
  }

  //#endregion

  //#region Pipe Functions

  public split(d: number | string) {
    return String(d ?? "").split("");
  }

  public canBeOpened(day: IAdventCalendarDayLite, currentTime: Date) {
    return day.date <= currentTime;
  }

  public isSameDay(currentTime: Date, day: Date) {
    return currentTime && day instanceof Date && isSameDay(currentTime, day);
  }

  //#endregion
}
