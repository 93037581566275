export const links = {
  hoferWebsite: {
    label: "HOFER.at",
    link: "https://www.hofer.at/de/homepage.html?utm_campaign=61d7f6da002f61e25b2a56d5935008d0&utm_source=adventkalender&utm_medium=footer&utm_content=hofer_logo&utm_term=alwayson",
  },
  angebote: {
    label: "Aktuelle Angebote",
    link: "https://www.hofer.at/de/angebote.html?utm_campaign=61d7f6da002f61e25b2a56d5935008d0&utm_source=adventkalender&utm_medium=footer&utm_content=angebote&utm_term=alwayson",
  },
  superDeal: {
    label: "AKTIONEN",
    link: "https://www.hofer.at/de/angebote/aktionen.html?utm_campaign=61d7f6da002f61e25b2a56d5935008d0&utm_source=adventkalender&utm_medium=footer&utm_content=aktionen&utm_term=alwayson",
  },
  flugblaetter: {
    label: "Aktuelle Flugblätter und Broschüren",
    link: "https://www.hofer.at/de/angebote/aktuelle-flugblaetter-und-broschuren.html?utm_campaign=61d7f6da002f61e25b2a56d5935008d0&utm_source=adventkalender&utm_medium=footer&utm_content=flugblaetter&utm_term=alwayson",
  },
  exklusivmarken: {
    label: "HOFER Eigenmarken",
    link: "https://www.hofer.at/de/sortiment/hofer-eigenmarken.html?utm_campaign=61d7f6da002f61e25b2a56d5935008d0&utm_source=adventkalender&utm_medium=footer&utm_content=eigenmarken&utm_term=alwayson",
  },
  onlineShop: {
    label: "HOFER Online Shop",
    link: "https://www.hofer.at/de/onlineshop/onlineshop-aktionsartikel.html?utm_campaign=61d7f6da002f61e25b2a56d5935008d0&utm_source=adventkalender&utm_medium=footer&utm_content=online-shop&utm_term=alwayson",
  },
  datenschutzImpressum: {
    label: "Datenschutzhinweis / Impressum",
    class: "allow-wrap",
    link: "https://www.hofer.at/de/informationen/datenschutzhinweis-impressum.html?utm_campaign=61d7f6da002f61e25b2a56d5935008d0&utm_source=adventkalender&utm_medium=footer&utm_content=ds-hinweis&utm_term=alwayson",
  },
  teilnahmebedingungen: {
    label: "Teilnahmebedingungen",
    routerLink: ["/teilnahmebedingungen"],
    hidden: true, // <- enabled via game info state in the footer
  },
  cookieSettings: {
    label: "Cookie-Einstellungen",
    link: "javascript:OneTrust.ToggleInfoDisplay();",
    target: "_self",
  },
  reisen: {
    label: "HOFER REISEN",
    link: "https://www.hofer-reisen.at?utm_source=hofer.at&utm_medium=referral&utm_campaign=adventkalender&utm_content=footer",
  },
  fotos: {
    label: "HOFER FOTOS",
    link: "https://www.hoferfotos.at/?wt_mc=at.csf.hoferadventkalender-2023.Footer.70448_2010-311222",
  },
  hot: {
    label: "HoT - HOFER TELEKOM",
    link: "https://www.hot.at?utm_source=hofer.at&utm_medium=referral&utm_campaign=adventkalender&utm_content=footer",
  },
  karriere: {
    label: "Karriere",
    link: "https://karriere.hofer.at/?utm_campaign=ta__adventskalender_at&utm_source=hofer_website&utm_medium=link_website&utm_content=awa_zgu_alle&jobPipeline=website&applySourceOverride=website",
  },
  rundUmWeihnachten1: {
    label: "Dummy Link",
    link: "#",
  },
  rundUmWeihnachten2: {
    label: "Dummy Link",
    link: "#",
  },
  rundUmWeihnachten3: {
    label: "Dummy Link",
    link: "#",
  },
  rundUmWeihnachten4: {
    label: "Dummy Link",
    link: "#",
  },
};
