export interface IReadyCheck {
  check(el: HTMLElement): boolean;
  event: string;
}

export const readyChecks = new Map<typeof HTMLElement, IReadyCheck>([
  [
    HTMLVideoElement,
    {
      check(el: HTMLVideoElement) {
        return el.readyState >= HTMLMediaElement.prototype.HAVE_FUTURE_DATA;
      },
      event: "canplay",
    },
  ],
  [
    HTMLImageElement,
    {
      check(el: HTMLImageElement) {
        return el.complete;
      },
      event: "load",
    },
  ],
]);

export async function isReady(mediaElement: HTMLImageElement | HTMLVideoElement) {
  const readyCheck = readyChecks.get(mediaElement?.constructor as typeof HTMLElement);
  if (readyCheck.check(mediaElement as any)) {
    return;
  }
  return new Promise(resolve => {
    mediaElement.addEventListener(readyCheck.event, () => resolve(), { once: true });
  });
}
