import { Component, Input } from "@angular/core";

@Component({
  selector: "app-container-with-floor",
  host: { class: "flex flex-col flex-auto min-h-full bg-blue-floor" },
  template: `<!-- <template> -->
    <div class="grid bg-blue-dark text-white" [ngClass]="{ 'main-min-height-without-top-bar flex-auto': minHeight }">
      <ng-content select="[hero-area-overlay]"></ng-content>
      <!-- <div class="area-span-full flex pointer-events-none bg-glance z-20"></div> -->
      <div class="area-span-full flex pointer-events-none">
        <div class="pointer-events-none bg-branch-right" [ngClass]="{ 'z-10': branchOnTop, 'z-0': !branchOnTop }"></div>
      </div>
      <div class="area-span-full flex flex-col" [class]="heroClass">
        <ng-content select="[hero-area]"></ng-content>
        <div class="bg-image-floor"></div>
      </div>
    </div>
    <ng-content></ng-content>
    <!-- </template> -->`,
})
export class ContainerWithFloorComponent {
  @Input()
  public heroClass: any = [];

  @Input()
  public minHeight = true;

  @Input()
  public branchOnTop = false;
}
