/**
 * @see https://wicg.github.io/shape-detection-api/#detectedbarcode
 */
export interface DetectedBarcode {
  readonly boundingBox: DOMRectReadOnly;
  readonly rawValue: string;
  readonly format: BarcodeFormat;
  readonly cornerPoints: {
    readonly x: number;
    readonly y: number;
  }[];
}

/**
 * @see https://wicg.github.io/shape-detection-api/#enumdef-barcodeformat
 */
export enum BarcodeFormat {
  /* eslint-disable @typescript-eslint/camelcase */
  aztec = "aztec",
  code_128 = "code_128",
  code_39 = "code_39",
  code_93 = "code_93",
  codabar = "codabar",
  data_matrix = "data_matrix",
  ean_13 = "ean_13",
  ean_8 = "ean_8",
  itf = "itf",
  pdf417 = "pdf417",
  qr_code = "qr_code",
  unknown = "unknown",
  upc_a = "upc_a",
  upc_e = "upc_e",
  /* eslint-enable @typescript-eslint/camelcase */
}

/**
 * @see https://wicg.github.io/shape-detection-api/#dictdef-barcodedetectoroptions
 */
export interface BarcodeDetectorOptions {
  formats?: BarcodeFormat[];
}
