type initValueFunc<T> = (i: number) => T;

export function shuffle<T = any>(a: T[]) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export function elements<T = any>(length: number, initValue?: T | initValueFunc<T>) {
  const valueFunc = initValue instanceof Function ? initValue : () => initValue;
  const arr: T[] = [];
  for (let i = 0; i < length; i++) {
    arr.push(valueFunc(i));
  }
  return arr;
}
