import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { getIsStandalone, getUserAgentInfo } from "@resee/user-agent";
import { parseJSON } from "date-fns";

import { GameInfoService } from "../../services/game-info";

const ADD_TO_HOME_SCREEN_HINT_LAST_CLOSED = "addToHomeScreenHintLastClosed";
const AR_TUTORIAL_LAST_VIEWED = "arTutorialLastViewed";

@Component({
  selector: "app-default-layout",
  templateUrl: "default.html",
  host: { class: "flex flex-col" },
})
export class DefaultLayout implements OnInit {
  public showAddToHomeScreen = false;

  public constructor(
    public readonly gameInfo: GameInfoService, //
    private readonly _router: Router,
    private readonly _modal: NgbModal,
  ) {}

  public ngOnInit() {
    const agent = getUserAgentInfo();
    const isStandalone = getIsStandalone();

    if (agent.os.name === "iOS" && !isStandalone) {
      const yesterday = new Date((new Date() as any) - 60 * 60 * 24 * 1000);
      let lastClosed = null;
      try {
        lastClosed = parseJSON(localStorage.getItem(ADD_TO_HOME_SCREEN_HINT_LAST_CLOSED));
      } catch {
        /* ignore  */
      }
      if (!(lastClosed > yesterday)) {
        this.showAddToHomeScreen = true;
        setTimeout(() => (this.showAddToHomeScreen = false), 30000);
      }
    }
  }

  public async openArView() {
    // const lastViewed = localStorage.getItem(AR_TUTORIAL_LAST_VIEWED);
    // if (!lastViewed) {
    //   const modalRef = this._modal.open(ArTutorialComponent, {
    //     //
    //     centered: true,
    //     scrollable: true,
    //     windowClass: "",
    //     keyboard: true,
    //     size: "full",
    //   });
    //   try {
    //     if (!(await modalRef.result)) {
    //       return;
    //     }
    //   } catch {
    //     return;
    //   }
    //   localStorage.setItem(AR_TUTORIAL_LAST_VIEWED, JSON.stringify(new Date()));
    // }
    await this._router.navigate(["/ar-view"]);
  }

  public closeAddToHomeScreen() {
    this.showAddToHomeScreen = false;
    localStorage.setItem(ADD_TO_HOME_SCREEN_HINT_LAST_CLOSED, JSON.stringify(new Date()));
  }

  public clearLocalStorage() {
    localStorage.removeItem(AR_TUTORIAL_LAST_VIEWED);
    localStorage.removeItem(ADD_TO_HOME_SCREEN_HINT_LAST_CLOSED);
  }
}
