import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from "@angular/platform-browser";

@Pipe({
  name: "rsTrustUrl",
  pure: true,
})
export class TrustUrlPipe implements PipeTransform {
  public constructor(private readonly _sanitizer: DomSanitizer) {}

  public transform(value: any, options = { type: "url" }): SafeUrl | SafeResourceUrl {
    return options?.type === "resource"
      ? this._sanitizer.bypassSecurityTrustResourceUrl(value)
      : this._sanitizer.bypassSecurityTrustUrl(value);
  }
}
