import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

import { IAdventCalendarDay, IAdventCalendarPrizeLite } from "../interfaces";
import { PrizeDetailModalContent } from "../modals/prize-detail/prize-detail";
import { AdventCalendarApiService } from "../services/advent-calendar.api";

@UntilDestroy()
@Component({
  templateUrl: "day.page.html",
})
export class DayPage {
  private readonly _dayNo$ = this._route.params.pipe(
    map((params) => (params["day"] === "AR" ? "AR" : Number(params["day"]))),
  );

  private readonly _day$$ = new BehaviorSubject<IAdventCalendarDay>(null);

  public readonly day$ = this._day$$.asObservable();

  public constructor(
    private readonly _route: ActivatedRoute, //
    private readonly _api: AdventCalendarApiService,
    private readonly _modal: NgbModal,
  ) {
    this._dayNo$
      .pipe(filter((dayNo) => isNumber(dayNo)))
      .pipe(map((dayNo) => dayNo as number))
      .pipe(switchMap((dayNo) => this._api.getDay(dayNo)))
      .pipe(untilDestroyed(this))
      .subscribe((day) => this._day$$.next(day));
  }

  //#region Modal

  public async openPrizeModal(prize: IAdventCalendarPrizeLite, day: IAdventCalendarDay) {
    const modalRef = this._modal.open(PrizeDetailModalContent, {
      //
      centered: true,
      scrollable: true,
      windowClass: "text-blue text-center",
      keyboard: true,
    });
    prize.day = { coupon: day.coupon, date: day.date };
    modalRef.componentInstance.prize = prize;
    try {
      return await modalRef.result;
    } catch (reason) {
      return reason;
    }
  }

  //#endregion
}

function isNumber(value: any): value is number {
  return typeof value === "number";
}
