import { Injectable } from "@angular/core";

@Injectable()
export class LocalStorageService {
  private readonly _cache = new Map<string, any>();

  public async get<T>(name: string, defaultValue: T) {
    const cachedValue = this._cache.get(name);
    if (cachedValue != null) {
      return cachedValue as T;
    }
    const value = await this._tryGetLocalStorageItem(name, defaultValue);
    this._cache.set(name, value);
    return value;
  }

  public async set<T>(name: string, value: T) {
    await this._setLocalStorageItem(name, value);
  }

  private async _tryGetLocalStorageItem<T>(name: string, defaultValue: T) {
    try {
      const v = localStorage.getItem(name);
      const j = JSON.parse(v) as T;
      return await Promise.resolve(j);
    } catch {
      return undefined;
    }
  }

  private async _setLocalStorageItem<T>(name: string, value: T) {
    if (value === null) {
      localStorage.removeItem(name);
    } else {
      const v = JSON.stringify(value);
      localStorage.setItem(name, v);
    }
    return await Promise.resolve();
  }
}
