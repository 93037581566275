import { Component } from "@angular/core";

import { GameInfoService } from "../../services/game-info";

@Component({
  templateUrl: "./header.html",
  selector: "app-header",
  host: {
    class: "flex flex-none sticky top-0 shadow-sm",
  },
})
export class HeaderComponent {
  public constructor(public readonly gameInfo: GameInfoService) {}
}
