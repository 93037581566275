import { Routes } from "@angular/router";

import { DayCanActivateGuard } from "./guards/day";
import { GameStateCanActivateGuard } from "./guards/game-state";
import { WinRedirectCanActivateGuard } from "./guards/win-redirect";
import { DefaultLayout } from "./layouts/default/default";
import { ConditionsOfParticipationPage } from "./pages/conditions-of-participation.page";
import { DayPage } from "./pages/day.page";
import { HintPage } from "./pages/hint.page";
import { HomePage } from "./pages/home.page";
import { NotFoundPage } from "./pages/not-found.page";
import { PrizesPage } from "./pages/prizes.page";

export const routes: Routes = [
  {
    path: "",
    component: DefaultLayout,
    children: [
      {
        path: "",
        component: HomePage,
      },
      {
        path: "gewinnen",
        component: DayPage,
        canActivate: [
          GameStateCanActivateGuard, //
          WinRedirectCanActivateGuard,
        ],
      },
      {
        path: "tag/:day",
        component: DayPage,
        canActivate: [
          GameStateCanActivateGuard, //
          DayCanActivateGuard,
        ],
      },
      {
        path: "preise",
        component: PrizesPage,
        canActivate: [GameStateCanActivateGuard],
        data: { title: "Preise" },
      },
      {
        path: "hinweis",
        component: HintPage,
        canActivate: [GameStateCanActivateGuard],
        data: { title: "Hinweis" },
      },
      {
        path: "teilnahmebedingungen",
        component: ConditionsOfParticipationPage,
        canActivate: [GameStateCanActivateGuard],
        data: { title: "Teilnahmebedingungen" },
      },
    ],
  },
  {
    path: "**",
    component: NotFoundPage,
    data: { title: "Seite nicht gefunden" },
  },
];
