import IllegalArgumentException from "@zxing/library/esm/core/IllegalArgumentException";
import InvertedLuminanceSource from "@zxing/library/esm/core/InvertedLuminanceSource";
import LuminanceSource from "@zxing/library/esm/core/LuminanceSource";

export class ImageDataLuminanceSource extends LuminanceSource {
  private readonly buffer: Uint8ClampedArray;

  public constructor(imageData: ImageData) {
    super(imageData.width, imageData.height);
    this.buffer = ImageDataLuminanceSource.getGrayscaleBuffer(imageData);
  }

  private static getGrayscaleBuffer({ data, width, height }: ImageData): Uint8ClampedArray {
    const grayscaleBuffer = new Uint8ClampedArray(width * height);
    for (let i = 0, j = 0; i < data.length; i += 4, j++) {
      const a = data[i + 3];
      if (a === 0) {
        grayscaleBuffer[j] = 0xff;
      } else {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        grayscaleBuffer[j] = (306 * r + 601 * g + 117 * b + 0x200) >> 10;
        // Luma Conversion: Photometric/digital ITU BT.709
        // -> Y = 0.2126 R + 0.7152 G + 0.0722 B
        // -> Y = 0.33 R + 0.5 G + 0.16 B
        // -> Y = (2 R + 3 G + B) / 6
        // grayscaleBuffer[j] = (2 * r + 3 * g + b) / 6;
        // if (grayscaleBuffer[j] > 127) {
        //   grayscaleBuffer[j] = 255;
        // } else {
        //   grayscaleBuffer[j] = 0;
        // }
      }
    }
    return grayscaleBuffer;
  }

  public getRow(y: number, row: Uint8ClampedArray): Uint8ClampedArray {
    if (y < 0 || y >= this.getHeight()) {
      throw new IllegalArgumentException();
    }
    const width = this.getWidth();
    const view = new Uint8ClampedArray(this.buffer.buffer, y * width, width);
    if (row === null || row.length < width) {
      row = new Uint8ClampedArray(width);
    }
    row.set(view);
    return row;
  }

  public getMatrix(): Uint8ClampedArray {
    return this.buffer;
  }

  public invert(): LuminanceSource {
    return new InvertedLuminanceSource(this);
  }
}
