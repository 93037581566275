import { Component, EventEmitter, Output } from "@angular/core";
import { BarcodeFormat } from "@zxing/library";
import { BehaviorSubject } from "rxjs";

@Component({
  templateUrl: "./scanner.html",
  selector: "app-scanner",
  host: {
    class: "",
  },
})
export class ScannerComponent {
  public availableDevices: MediaDeviceInfo[];
  public deviceCurrent: MediaDeviceInfo;
  public deviceSelected: string;

  public formatsEnabled: BarcodeFormat[] = [BarcodeFormat.QR_CODE];

  public hasDevices: boolean;
  public hasPermission: boolean;

  public torchEnabled = false;
  public torchAvailable$ = new BehaviorSubject<boolean>(false);
  public tryHarder = false;

  @Output()
  public readonly qrCodeResult = new EventEmitter();

  @Output()
  public readonly permissionStatus = new EventEmitter();

  public constructor() {}

  public onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  public onCodeResult(resultString: string) {
    this.qrCodeResult.emit(resultString);
  }

  public onDeviceSelectChange(selected: string) {
    const selectedStr = selected || "";
    if (this.deviceSelected === selectedStr) {
      return;
    }
    this.deviceSelected = selectedStr;
    const device = this.availableDevices.find((x) => x.deviceId === selected);
    this.deviceCurrent = device || undefined;
  }

  public onDeviceChange(device: MediaDeviceInfo) {
    const selectedStr = device?.deviceId || "";
    if (this.deviceSelected === selectedStr) {
      return;
    }
    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || undefined;
  }

  public onHasPermission(has: boolean) {
    this.hasPermission = has;
    this.permissionStatus.emit(has);
  }

  public onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  public toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  public toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }
}
