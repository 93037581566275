import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { environment } from "../environment";
import { links } from "../links";
import { ArVideoModalContent } from "../modals/ar-video/ar-video.modal";
import { GameInfoService, GameStatus } from "../services/game-info";
import { SnowCanvas } from "../snow";
import * as text from "../texts";

// import gsap from "gsap";

@UntilDestroy()
@Component({
  templateUrl: "./home.page.html",
  host: { class: "flex" },
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePage implements AfterViewInit, OnDestroy {
  //#region Properties

  public readonly links = links;

  private _snowCanvas: SnowCanvas;

  // private _snowCanvasBehind: SnowCanvas;

  @ViewChild("snowContainer")
  public snowContainerRef: ElementRef<HTMLElement>;

  @ViewChild("snowContainerBehind")
  public snowContainerBehindRef: ElementRef<HTMLElement>;

  // @ViewChild("snow")
  // public snowCanvasRef: ElementRef<HTMLCanvasElement>;

  // @ViewChild("snowBehind")
  // public snowCanvasRefBehind: ElementRef<HTMLCanvasElement>;

  public readonly text = text;

  //#endregion

  //#region Constructor

  public constructor(
    public readonly gameInfo: GameInfoService, //
    private readonly _modal: NgbModal,
    private readonly _zone: NgZone,
  ) {}

  //#endregion

  //#region Lifecycle Hooks

  public ngAfterViewInit() {
    const snowContainerElement = this.snowContainerRef?.nativeElement;
    const snowContainerBehindElement = this.snowContainerBehindRef?.nativeElement;
    if (snowContainerElement && environment.config.enableSnow) {
      this._zone.runOutsideAngular(() => {
        this._snowCanvas = new SnowCanvas({
          //
          container: snowContainerElement,
          containerBehind: snowContainerBehindElement,
          fpsLimit: 30,
          speed: 0.2,
          // fpsLimit: 0.15,
          // flakeCount: 200,
          // resetOnNewContainerDimensions: true,
          // onFrame: () => {
          //   this._snowCanvas.toggleClassOnContainersParent("show-snow", true);
          //   setTimeout(() => this._snowCanvas.toggleClassOnContainersParent("show-snow", false), 5500);
          // },
        });
      });
      // this.gameInfo.status$.pipe(untilDestroyed(this)).subscribe((status) => {
      //   this._zone.runOutsideAngular(() => {
      //     if (status === GameStatus.Running) {
      //       setTimeout(() => {
      //         this._snowCanvas.refreshContainerDimensions();
      //         this._snowCanvas.resetFlakes();
      //         this._snowCanvas.start();
      //       }, 500);
      //     } else {
      //       this._snowCanvas.stop();
      //     }
      //   });
      // });
    }
  }

  public ngOnDestroy() {
    try {
      this._snowCanvas?.stop();
    } catch {
      /* ignore */
    }
  }

  //#endregion

  //#region Modal

  public async openVideoModal(event: MouseEvent) {
    event?.stopPropagation();
    event?.preventDefault();

    const modalRef = this._modal.open(ArVideoModalContent, {
      //
      centered: true,
      scrollable: true,
      keyboard: true,
    });
    try {
      await modalRef.result;
    } catch {
      /* ignore */
    }
  }

  //#endregion

  public isSnowing() {
    return !!this._snowCanvas?._snowing;
  }

  public toggleSnow() {
    if (environment.config.enableSnow) {
      if (this.isSnowing()) {
        this._snowCanvas.stop();
      } else {
        this._snowCanvas.refreshContainerDimensions();
        this._snowCanvas.resetFlakes();
        this._snowCanvas.start();
      }
    }
  }
}
