import { IAdventCalendarDayLite } from "./interfaces";

export interface IAppEnvironmentConfig {
  apiBaseUrlPrefix: string;
  calendarDayPatches: Partial<IAdventCalendarDayLite>[];
  calendarLayoutMap: number[][][];
  calendarLayoutMapMobile: number[][][];
  enableSnow: boolean;
}

export interface IAppEnvironment {
  version: string;
  production: boolean;
  enableTracing?: boolean;
  enableServiceWorker?: boolean;
  config: IAppEnvironmentConfig;
}

export function environmentConfig(config: Partial<IAppEnvironmentConfig>) {
  return Object.assign({}, baseConfig, config) as IAppEnvironmentConfig;
}

const baseConfig: Partial<IAppEnvironmentConfig> = {
  calendarDayPatches: [
    { day: 1, size: [2, 1], theme: "dots" },
    { day: 2, size: [1, 1] },
    { day: 3, size: [1, 1], theme: "stripes" },
    { day: 4, size: [1, 2] },
    { day: 5, size: [3, 1], theme: "dots" },
    { day: 6, size: [2, 2], theme: "stripes" },
    { day: 7, size: [1, 1], theme: "dots" },
    { day: 8, size: [1, 1] },
    { day: 9, size: [1, 1] },
    { day: 10, size: [1, 1] },
    { day: 11, size: [1, 1] },
    { day: 12, size: [1, 1], theme: "stripes" },
    { day: 13, size: [1, 1] },
    { day: 14, size: [1, 1], theme: "dots" },
    { day: 15, size: [1, 1] },
    { day: 16, size: [1, 1] },
    { day: 17, size: [1, 1], theme: "stripes" },
    { day: 18, size: [2, 1], theme: "dots" },
    { day: 19, size: [1, 1], theme: "stripes" },
    { day: 20, size: [1, 1] },
    { day: 21, size: [1, 1], theme: "stripes" },
    { day: 22, size: [1, 1] },
    { day: 23, size: [1, 2] },
    { day: 24, size: [2, 2], theme: "gold" },
  ],
  calendarLayoutMap: [
    [
      [1, 1, 23],
      [2, 19, 23],
      [5, 5, 5],
    ],
    [
      [6, 6, 3],
      [6, 6, 20],
      [22, 14, 11],
    ],
    [
      [15, 13, 17],
      [18, 18, 10],
      [12, 16, 7],
    ],
    [
      [8, 21, 9],
      [4, 24, 24],
      [4, 24, 24],
    ],
  ],
  calendarLayoutMapMobile: [
    [
      [1, 1, 4],
      [2, 3, 4],
      [5, 5, 5],
    ],
    [
      [6, 6, 7],
      [6, 6, 8],
      [9, 10, 11],
    ],
    [
      [12, 13, 14],
      [15, 16, 17],
      [18, 18, 19],
    ],
    [
      [20, 21, 22],
      [23, 24, 24],
      [23, 24, 24],
    ],
  ],
  enableSnow: false,
};
