import UAParser from "ua-parser-js";

export interface IUserAgentInfo {
  ua: string;
  browser: {
    name: string;
    version: string;
  };
  cpu: {
    architecture: string;
  };
  device: {
    vendor: string;
    model: string;
    type: string;
  };
  engine: {
    name: string;
    version: string;
  };
  os: {
    name: string;
    version: string;
  };
}

export function getUserAgentInfo(ua?: string): IUserAgentInfo {
  return UAParser(ua);
}

export function getIsStandalone() {
  const isStandalone = window.navigator["standalone"] === true;
  return isStandalone || window.matchMedia("(display-mode: standalone)").matches;
}
