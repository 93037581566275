import { formatNumber } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "appDistance", pure: true })
export class DistancePipe implements PipeTransform {
  public transform(value: any): any {
    let distance = Number(value);
    let unit = "m";
    let digitsInfo = "1.0-0";
    if (distance > 1000) {
      distance /= 1000;
      unit = "km";
      digitsInfo = "1.2-2";
    }
    return formatNumber(distance, "de-AT", digitsInfo) + " " + unit;
  }
}
