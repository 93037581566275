import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isAfter, parseISO, sub } from "date-fns";
import { map } from "rxjs/operators";

import {
  IAdventCalendarDay,
  IAdventCalendarDayLite,
  IAdventCalendarInfo,
  IAdventCalendarPrize,
  IAdventCalendarWinRequest,
  IAdventCalendarWinResponse,
  IWithCoverImage,
} from "../interfaces";

export const DEFAULT_GAME_START_TIME = "2024-12-01T00:00:00+0100";
export const DEFAULT_GAME_END_TIME = "2024-12-25T00:00:00+0100";

@Injectable()
export class AdventCalendarApiService {
  public constructor(private readonly http: HttpClient) {}

  public getCalendarInfo() {
    return this.http
      .get<IAdventCalendarInfo>(`api://v1/advent-calendar/info`) //
      .pipe(
        map((info) => {
          info.currentTime = parseISO(info.currentTime as string);

          info.startTime = parseISO((info.startTime as string) ?? DEFAULT_GAME_START_TIME);
          info.endTime = parseISO((info.endTime as string) ?? DEFAULT_GAME_END_TIME);

          // info.arAvailable = !!(info.arAvailable ?? info["ar_available"]);
          // delete info["ar_available"];

          return info;
        }),
      );
  }

  public getDays() {
    return this.http
      .get<IAdventCalendarDayLite[]>(`api://v1/advent-calendar/days`) //
      .pipe(map((days) => days?.map((d) => patchDayResponse(d))));
  }

  public getDay(day: number) {
    return this.http
      .get<IAdventCalendarDay>(`api://v1/advent-calendar/days/${day}`) //
      .pipe(
        map((day) => {
          patchDayResponse(day);
          patchItemWithCoverImageResponse(day.prize);
          return day;
        }),
      );
  }

  public getPrizes() {
    return this.http
      .get<IAdventCalendarPrize[]>(`api://v1/advent-calendar/prizes`) //
      .pipe(
        map(
          (result) =>
            result?.map((r, i) => {
              patchItemWithCoverImageResponse(r);
              r.id = i + 1;
              return r;
            }) ?? [],
        ),
      );
  }

  public win(body: IAdventCalendarWinRequest) {
    return this.http.post<IAdventCalendarWinResponse>(`api://v1/advent-calendar/win`, body);
  }
}

function patchItemWithCoverImageResponse<T extends IWithCoverImage>(item: T) {
  item.coverImage = "resource://" + item.coverImage;
  return item;
}

function patchDayResponse<T extends IAdventCalendarDayLite>(day: T) {
  day.date = parseISO(day.date as string);
  return day;
}
