export const prizesText = `
  <p>
    Es ist wieder soweit - <b>Weihnachten steht vor der Tür!</b>
  </p>
  <p>
    Kekse werden gebacken, Lieder gesungen, Geschenke besorgt - der <b>Advent</b> ist alle Jahre wieder eine ganz
    besondere und vor allem <b>besinnliche Zeit</b>. Gemeinsam mit der <b>Familie oder auch Freunden</b>
    feiert man diese Zeit und wartet auf das große Weihnachtsfest.
  </p>
  <p>
    Um das Warten auf den großen Abend erträglicher zu machen, gibt es für Kinder und auch Erwachsene die schöne
    <b>Tradition des Adventkalenders</b>. Türchen für Türchen zum 24. Dezember - eine kleine Überraschung an jedem Tag
    verkürzt das Warten für jeden - <b>egal, ob Groß oder Klein.</b>
  </p>
`;

export const winBackText = `
  <p>
    Auch in diesem Jahr hält der Adventkalender wieder etwas Besonderes für Sie bereit.
    An ausgewählten und im Kalender gekennzeichneten Tagen können Sie Ihren Einkauf als Warengutschein zurück gewinnen.
  </p>
  <p>
    Einfach das gekennzeichnete Türchen öffnen, ein Foto Ihres Kassenbon hochladen oder den aufgedruckten QR-Code scannen.
    Geben Sie noch Ihre Daten ein und sichern Sie sich die Chance auf einen Warengutschein.
  </p>
`;

export const arTutorialDescription = {
  step1Text: `
    <p>
      Sobald das <b>pulsierende Filial-Icon</b> am unteren Bildschirmrand zu sehen ist kann es losgehen!
    </p>
  `,
  step2Text: `
    <p>
      Begeben Sie sich in eine <b>Filiale</b> in Ihrer Umgebung und wechseln Sie über das Filial-Icon in
      die <b>Augmented Reality Ansicht</b>.
    </p>
  `,
  step3Text: `
    <p>
      Nun können Sie mit Ihrem Smartphone die <b>Umgebung erkunden</b> und mit etwas Glück unser
      <b>verstecktes Türchen finden</b>.
    </p>
  `,
  step4Text: `
    <p>
      Haben Sie das Türchen entdeckt und geöffnet müssen Sie nur noch das <b>Gewinnspiel-Formular ausfüllen</b> um
      teilzunehmen.
    </p>
  `,
};

export const pwaHintText = `
  <p>
    Tipp: Legen Sie den Adventkalender am Startbildschirm Ihres Smartphones ab um schnell und
    jederzeit alle Türchen öffnen zu können.
  </p>
`;
