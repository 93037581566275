import { Component } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { environment } from "../../environment";
import { links } from "../../links";
import { GameInfoService } from "../../services/game-info";

@UntilDestroy()
@Component({
  templateUrl: "./footer.html",
  selector: "app-footer",
  host: { class: "flex-none" },
})
export class FooterComponent {
  //#region Properties

  public readonly version = environment.version;

  public readonly websiteLink = links.hoferWebsite;

  public readonly linkListAngebote = {
    title: "Angebote",
    $implicit: [links.angebote, links.superDeal, links.flugblaetter, links.exklusivmarken, links.onlineShop],
  };

  public readonly linkListInformationen = {
    title: "Informationen",
    $implicit: [links.datenschutzImpressum, links.teilnahmebedingungen, links.cookieSettings],
  };

  public readonly linkListHoferImWeb = {
    $implicit: [links.reisen, links.fotos, links.hot, links.karriere],
  };

  //#endregion

  //#region Constructor

  public constructor(private readonly gameInfo: GameInfoService, private sanitizer: DomSanitizer) {
    this.gameInfo.status$.pipe(untilDestroyed(this)).subscribe((s) => {
      links.teilnahmebedingungen.hidden = !(s === "running");
    });
  }

  //#endregion

  //#region Utility Methods

  public scrollToTop() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  public sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  //#endregion
}
