import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IAdventCalendarPrize } from "../../interfaces";

@Component({
  templateUrl: "prize-detail.html",
  host: { class: "flex flex-auto p-16 backdrop-area" },
})
export class PrizeDetailModalContent {
  @Input()
  public prize: IAdventCalendarPrize;

  public constructor(public readonly modal: NgbActiveModal) {}
}
