import { Component, Injectable, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ComponentStore } from "@ngrx/component-store";
import { Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";

import { IAdventCalendarPrizeLite } from "../interfaces";
import { PrizeDetailModalContent } from "../modals/prize-detail/prize-detail";
import { AdventCalendarApiService } from "../services/advent-calendar.api";
import { prizesText } from "../texts";

interface IActionMessage {
  message: string;
  type: string;
}

export interface PrizeState {
  actionMessage?: IActionMessage;
  isLoading?: boolean;
  prizes: IAdventCalendarPrizeLite[];
}

@Injectable()
export class PrizeStore extends ComponentStore<PrizeState> {
  public readonly actionMessage$ = this.select((state) => state.actionMessage);

  public readonly isLoading$ = this.select((state) => !!state.isLoading);

  public readonly prizes$ = this.select((state) => state.prizes);

  public readonly updatePrizes = this.updater((
    state,
    prizes: IAdventCalendarPrizeLite[], //
  ) => ({ ...state, prizes, isLoading: false }));

  public readonly setLoading = this.updater((
    state,
    isLoading: boolean, //
  ) => ({ ...state, isLoading }));

  public readonly setActionMessage = this.updater((
    state,
    actionMessage: IActionMessage, //
  ) => ({ ...state, actionMessage }));

  public constructor() {
    super({ prizes: [] });
  }

  public wrapInLoading<T>(observable: Observable<T>) {
    this.setActionMessage(null);
    this.setLoading(true);
    return observable.pipe(finalize(() => this.setLoading(false)));
  }
}

// -------------------------------------

@Component({
  templateUrl: "prizes.page.html",
  providers: [PrizeStore],
})
export class PrizesPage implements OnInit {
  //#region Properties

  @ViewChild("prizeModal", { read: TemplateRef })
  public prizeModalTemplateRef: TemplateRef<{ modal: NgbModalRef }>;

  public readonly actionMessage$ = this._store.actionMessage$;

  public readonly isLoading$ = this._store.isLoading$;

  public readonly prizes$ = this._store.prizes$;

  public readonly text = prizesText;

  //#endregion

  //#region Constructor

  public constructor(
    private readonly _store: PrizeStore, //
    private readonly _api: AdventCalendarApiService,
    private readonly _modal: NgbModal,
  ) {}

  //#endregion

  //#region Lifecycle Hooks

  public async ngOnInit() {
    const prizes$ = this._store.wrapInLoading(
      this._api
        .getPrizes() //
        .pipe(
          catchError((err) => {
            this._store.setActionMessage({ message: "FEHLER", type: "error" });
            return of([]);
          }),
        ),
    );
    this._store.updatePrizes(prizes$);
  }

  //#endregion

  //#region Modal

  public async openPrizeModal(prize: IAdventCalendarPrizeLite) {
    const modalRef = this._modal.open(PrizeDetailModalContent, {
      //
      centered: true,
      scrollable: true,
      windowClass: "text-blue text-center",
      keyboard: true,
    });
    modalRef.componentInstance.prize = prize;
    try {
      return await modalRef.result;
    } catch (reason) {
      return reason;
    }
  }

  //#endregion

  //#region Utility Methods

  public trackByUnique(prize: IAdventCalendarPrizeLite) {
    return prize.id;
  }

  //#endregion
}
