import { importSPKI, formatDateTime, formatNumber, Receipt } from "@various/rksv/src/index";

// Glossary:
// - Magnetkartenleser / Magnetic Stripe Reader (MSR)

export const signaturePublicKeys = {
  // Hofer KG RKSV Intermediate CA | valid until: 2026-07-16
  "U:ATU24963706-1": `-----BEGIN PUBLIC KEY-----MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEBOVaGyo/g0BID5i+0B3AaaH8bSe68CGGfrKbKV28WjgQmV9CFachy6nXkAzg6cvsdAEuHRkABnIE+chcYWyIpA==-----END PUBLIC KEY-----`,
  // Hofer Root CA | valid until: 2032-09-11
  "U:ATU78172745-2": `-----BEGIN PUBLIC KEY-----MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAE/IHY9UW8wZsUIPE5AP0/Dmq0GXtTAWOHhQBJGx4hzEK1noYqoUKBj4xyCa/NRIMO/6ABFRjDQYyVXQQ3b4t13g==-----END PUBLIC KEY-----`,
};

export async function decodeAndVerifyReceiptData(data) {
  const receipt = new Receipt(data);
  const signaturePublicKey = signaturePublicKeys[receipt.info.certificateSerialNumber];
  if (!signaturePublicKey) {
    throw new Error(`Receipt cannot be checked`);
  }
  const key = await importSPKI(signaturePublicKey);
  const isValid = await receipt.isValid(key);
  if (!isValid) {
    throw new Error(`Receipt is not valid`);
  }

  // Cashbox ID: <divNo:[0-9]{3}>/<storeNo:[0-9]{3}> | <locationId:[a-z0-9]{4}>
  const cashboxId = receipt.info.cashboxId;
  const receiptNo = receipt.info.receiptNo;
  const dateTime = receipt.info.dateTime;
  const sumTotal = receipt.info.sumTotal;

  const [_, tillId, counter] = receiptNo.match(/^([0-9]+).*?([0-9]+)$/) ?? [];
  const cashierId = "XXX"; // Unable to extract this info from receipt
  const receiptNoFull = `${counter} ${cashboxId}/${tillId}/${cashierId}`;

  const dateTimeFormatted = formatDateTime(dateTime);
  const sumTotalFormatted = formatNumber(sumTotal);

  return {receiptNoFull, dateTime, dateTimeFormatted, sumTotal, sumTotalFormatted};
}
