import "./polyfills";

import { enableProdMode, ViewEncapsulation } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { applyUserAgentCssClasses } from "@resee/user-agent";
import { fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";
import Swiper, { Pagination } from "swiper";

import { AppModule } from "./app.module";
import { environment } from "./environment";
import { setupAndInitializeWonderPush } from "./wonderpush";

async function bootstrap() {
  if (environment.production) {
    enableProdMode();
  }
  applyUserAgentCssClasses();
  await platformBrowserDynamic().bootstrapModule(AppModule, {
    defaultEncapsulation: ViewEncapsulation.None,
    ngZone: "zone.js",
    ngZoneEventCoalescing: true,
  });
}

Swiper.use([Pagination]);

(async function () {
  await bootstrap();
  setupAndInitializeWonderPush();
})();

function onScroll() {
  document.body.classList.toggle("is-scroll-position-top", window.scrollY === 0);
}

fromEvent(window, "scroll")
  .pipe(debounceTime(50))
  .subscribe((_) => onScroll());
onScroll();

window.onerror = undefined;
